import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import { PROVIDER_NAME } from '../../../entities/user/ProfileSettings/components/AccountSwitch/AccountSwitch';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useAuthDBService } from '../../services/AuthDB.service';
import { TProvider } from '../AuthLogin/components/ExternalProvider/types';
import {
  LOCAL_STORAGE_KEY_CONNECT_PROVIDER,
  LOCAL_STORAGE_KEY_CONNECT_TOKEN,
} from '../const';
import { getQueryVariable } from '../helpers';

export const useConnectToken = () => {
  const history = useHistory();
  const authDBService = useAuthDBService();
  const googleAuthCode = getQueryVariable('connect_token');
  const { setItem, getItem, removeItem } = useLocalStorage();

  useEffect(() => {
    if (googleAuthCode) {
      setItem(LOCAL_STORAGE_KEY_CONNECT_TOKEN, googleAuthCode);
    }
  }, [googleAuthCode]);

  const getConnectToken = () => {
    return getItem(LOCAL_STORAGE_KEY_CONNECT_TOKEN);
  };

  const getConnectProvider = () => {
    return getItem(LOCAL_STORAGE_KEY_CONNECT_PROVIDER);
  };

  const removeConnectData = () => {
    removeItem(LOCAL_STORAGE_KEY_CONNECT_TOKEN);
    removeItem(LOCAL_STORAGE_KEY_CONNECT_PROVIDER);
  };
  const setConnectProvider = (provider: string) => {
    setItem(LOCAL_STORAGE_KEY_CONNECT_PROVIDER, provider);
  };

  const bindAccount = (id: number) => {
    const token = googleAuthCode || getConnectToken();
    const provider = getConnectProvider() as TProvider | null;
    if (id && token) {
      const bindUser = async () => {
        try {
          await authDBService.bindExternalUser(token);
          const providerName =
            (provider !== null && PROVIDER_NAME[provider].name) || '';
          Notification.success({
            title: `The user is successfully bound with ${providerName} account`,
          });
        } catch (err) {
          console.error(err);
        } finally {
          removeConnectData();
          history.push(window.location.pathname);
        }
      };
      bindUser();
    }
  };

  return {
    bindAccount,
    removeConnectData,
    getConnectProvider,
    setConnectProvider,
  };
};
