import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

const SymbolDBUIId = 'symboldb-ui-react';

const SymbolDBU = () => {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('symboldb-bundle');

  setStartHandleTime();

  useEffect(() => {
    if (scriptIsLoaded && window.SYMBOLDB_UI_REACT) {
      window.SYMBOLDB_UI_REACT.render(document.getElementById(SymbolDBUIId));
    }
  }, []);

  const handleScriptInjectCB = useCallback((newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.SYMBOLDB_UI), () => {
      setScriptIsLoaded(true);
      logHandleTime();
      if (window.SYMBOLDB_UI_REACT) {
        window.SYMBOLDB_UI_REACT.render(document.getElementById(SymbolDBUIId));
      }
    });
  }, []);

  const baseUrl = getApiUrl(Service.SymbolDBUI);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          scriptIsLoaded
            ? []
            : [
                {
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id={SymbolDBUIId} />
    </>
  );
};

export default SymbolDBU;
