export enum FormState {
  EMAIL = 'email',
  SMS = 'sms',
  GA = 'totp',
  CODE = 'code',
}

export const QRCodeWidth = 168;

export const UniqIdLength = 10;

export const TimerValue = 60;
export const EndTimeValue = 0;

export const GA_DIGIT_INPUT_LENGTH = 6;
export const EMAIL_DIGIT_INPUT_LENGTH = 8;
export const SMS_DIGIT_INPUT_LENGTH = 4;

export const PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
export const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
