import { FC } from 'react';

import { SettingsFormValues } from '../../const';
import { IProfilePasswordEdit } from '../../types';
import { FormGroup } from '../FormGroup/FormGroup';
import { ProfileInputField } from '../ProfileInputField/ProfileInputField';

export const ProfilePasswordEdit: FC<
  React.PropsWithChildren<IProfilePasswordEdit>
> = ({ handleSubmit, editField, setEditField }) => {
  const isOpen = editField === SettingsFormValues.PASSWORD;

  return (
    <FormGroup>
      {isOpen ? null : 'Password'}
      <ProfileInputField
        isClose={false}
        editField={editField}
        setEditField={setEditField}
        name={SettingsFormValues.PASSWORD}
        onSave={handleSubmit}
        value="********"
      />
    </FormGroup>
  );
};
