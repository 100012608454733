import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledProps } from '../../shared/helpers/types';

export const ResultPopupStyled = styled('div')(({ theme }: StyledProps) => ({
  maxHeight: '464px',
  overflowY: 'scroll',
  backgroundColor: theme?.color?.bg?.primary,
}));

export const ItemStyled = styled('div')(({ theme }: StyledProps) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  color: theme?.color?.typo?.action,
  fontSize: '13px',
  width: '100%',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  borderTop: '1px solid #EBEBEB',
}));

export const TitleStyled = styled('div')(({ theme }: StyledProps) => ({
  overflow: 'hidden',
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 12px 8px',
  justifyContent: 'space-between',
  fontWeight: '500',
  fontSize: '17px',
  color: theme?.color?.typo?.primary,
  '&:hover': {
    backgroundColor: theme?.color?.table?.bg?.hover,
    cursor: 'pointer',
    color: theme?.color?.typo?.promo,
  },
}));

export const IconWithTextStyled = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
}));

export const LoaderStyled = styled('div')(() => ({
  padding: '8px 12px',
}));

export const SearchResultStyled = styled('div')(({ theme }: StyledProps) => ({
  fontWeight: '400',
  fontSize: '13px',
  padding: '8px 38px 8px 48px',
  '&:hover': {
    backgroundColor: theme?.color?.table?.bg?.hover,
    cursor: 'pointer',
    color: theme?.color?.typo?.promo,
  },
}));

export const StyledChip = styled(Chip)(({ theme }: StyledProps) => ({
  border: `1.5px solid ${theme?.color?.typo?.action}`,
  borderRadius: '32px',
  height: '24px',
  '.MuiChip-label': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    color: theme?.color?.typo?.action,
  },
}));
