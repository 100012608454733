import { Alert } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { useApi, Service } from '../../config/useApi';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { useScriptInject } from '../shared/helpers';
import { useAlert } from '../shared/hooks';

import { getRoute } from './utils';

const SymbolDB = () => {
  const location = useLocation();
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();
  // TODO: move to state manager
  const [sdbScriptsLoaded, setSdbScriptsLoaded] = useState(false);
  const element = document.getElementsByClassName('Content')[0];
  const { showAlert } = useAlert(sdbScriptsLoaded, MODULES.SYMBOLDB);
  const baseUrl = getApiUrl(Service.SymbolDB);

  useEffect(() => {
    if (window.SYMBOLDB_UI) {
      window.SYMBOLDB_UI.goto(element, getRoute(location));
    }
  }, [element, location]);

  const handleScriptInjectCB = useCallback(
    (newState: any, addedTags: any) => {
      handleScriptInject(addedTags, toLowerCase(MODULES.SYMBOLDB), () => {
        // eslint-disable-next-line  @typescript-eslint/no-unused-expressions
        setSdbScriptsLoaded(true);
        if (window.SYMBOLDB_UI) {
          window.SYMBOLDB_UI.render(
            document.getElementsByClassName('Content')[0],
            getRoute(location),
          );
        }
      });
    },
    [location],
  );

  return (
    <div>
      <div className="symboldb">
        <Helmet
          script={
            sdbScriptsLoaded
              ? []
              : [
                  {
                    src: `${baseUrl}/editor/assets/js/script.js`,
                  },
                ]
          }
          onChangeClientState={handleScriptInjectCB}
        >
          <link
            rel="stylesheet"
            href={`${baseUrl}/editor/assets/css/style.css`}
          />
          <link
            rel="stylesheet"
            href={`${baseUrl}/editor/assets/font/fontawesome-webfont.woff?v=3.2.1`}
          />
        </Helmet>
        <div data-ng-include="'tpl/view.html'" />
        {showAlert && (
          <Alert className="AlertVpn" severity="warning">
            VPN is needed to access <strong>Symboldb</strong>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default SymbolDB;
