import { Service } from '../auth/const';
import {
  getAccessToken,
  getSessionByModuleName,
  getUsername,
} from '../services/Cookies.service';

export const getRequest = (
  url: string,
  sessionId?: string,
  accessToken?: string,
) => {
  const BASE_URL = `${window.WORKFLOW_UI?.api_urls?.WORKFLOW}/api/v2`;
  let options;

  if (sessionId) {
    options = {
      method: 'GET',
      headers: {
        'x-auth-sessionid': sessionId,
      },
    };
  }
  if (accessToken) {
    options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
  if (!sessionId && !accessToken) {
    options = {
      method: 'GET',
    };
  }

  return fetch(`${BASE_URL}${url}`, options);
};

export const fetchApprovals = (
  setCountApprovals: (countApprovals: number | null) => void,
) => {
  const userName = getUsername();
  const token = getAccessToken();
  const accessToken = token?.access_token;
  const workflowSessionId = getSessionByModuleName(Service.Workflow);
  const url = `/workflow_execution/?status=suspended&approver=${
    userName || ''
  }&limit=1000`;

  if (workflowSessionId || accessToken) {
    getRequest(url, workflowSessionId, accessToken)
      .then((response) => {
        if (!response.ok) {
          console.error(`status code: ${response.status}`);
          return null;
        }
        return response.json();
      })
      .then((data) => data && setCountApprovals(data?.count));
  }
};
