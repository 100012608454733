import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SettingsFormValues } from '../const';
import { IUseFieldProps, ProfileSettingsFormValues } from '../types';

export const useField = ({
  setEditField,
  name,
  editField,
  onSave,
}: IUseFieldProps) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const isOpen = editField === name;
  const canEdit = isOpen || editField === null;

  const {
    formState: { isSubmitting },
    handleSubmit,
    trigger,
    getValues,
    reset,
  } = useFormContext<ProfileSettingsFormValues>();

  const handleEdit = () => {
    if (typeof setEditField === 'function') {
      setEditField(name);
    }
  };

  const handleSave = useCallback(
    (event: any, fieldName: any) => {
      if (event?.stopPropagation === 'function') {
        event?.stopPropagation();
      }

      handleSubmit(async (values) => {
        const isValid = await trigger(fieldName);

        if (!isValid) {
          return;
        }

        setIsWaiting(true);

        if (typeof onSave === 'function') {
          // eslint-disable-next-line consistent-return
          return onSave(values);
        }
      })();
    },
    [handleSubmit, onSave],
  );

  useEffect(() => {
    if (isSubmitting) {
      return;
    }

    if (isWaiting) {
      setIsWaiting(false);

      if (typeof setEditField === 'function') {
        setEditField(null);
      }

      const values = getValues();
      reset({ ...values, [SettingsFormValues.CODE]: '' });
    }
  }, [isWaiting, isSubmitting]);

  return {
    isWaiting,
    handleEdit,
    setIsWaiting,
    isOpen,
    canEdit,
    handleSave,
  };
};
