import { styled } from '@mui/material/styles';
import { FC } from 'react';

import ShieldCheckIcon from '../../../../../assets/i/ShieldCheck.svg';
import { IMessage } from '../../types';

const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F2F8F2',
  padding: '8px',
  borderRadius: '4px',
  fontSize: '13px',
  color: '#4E5D60',
  marginTop: '8px',
}));

const Icon = styled('img')(() => ({
  marginRight: '8px',
}));

export const Message: FC<React.PropsWithChildren<IMessage>> = ({ message }) => (
  <Root>
    <Icon alt="success" src={ShieldCheckIcon} />
    {message}
  </Root>
);
