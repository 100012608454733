import { FC, useContext } from 'react';

import { ConfigContext } from '../../../config/ConfigContext';
import { AppVersion } from '../../shared/AppVersion/AppVersion';
import { AuthLayoutProps } from '../types';

import {
  StyledAuthContent,
  StyledAuthLayout,
  StyledAuthLogo,
  StyledAuthWrapper,
} from './AuthLayout.styled';

export const AuthLayout: FC<React.PropsWithChildren<AuthLayoutProps>> = ({
  children,
}) => {
  const { logo } = useContext(ConfigContext);

  return (
    <StyledAuthLayout className="AuthLayout">
      <StyledAuthWrapper className="AuthWrapper">
        <StyledAuthContent className="AuthContent">
          {logo && (
            <StyledAuthLogo className="AuthLogo">
              <img className="AuthLogoSvg" src={logo} alt="logo" />
            </StyledAuthLogo>
          )}
          {children}
        </StyledAuthContent>
      </StyledAuthWrapper>
      <AppVersion />
    </StyledAuthLayout>
  );
};
