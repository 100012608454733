import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useApi, Service } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { getReleasePostfix, useScriptInject } from '../shared/helpers';

const NotificationsShaperId = 'notifications-shaper';

const NotificationsShaper = () => {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const { handleScriptInject } = useScriptInject();
  const { getApiUrl } = useApi();

  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'notifications-shaper-bundle',
  );

  setStartHandleTime();

  useEffect(() => {
    if (scriptIsLoaded && window.NOTIFICATIONS_SHAPER_UI) {
      window.NOTIFICATIONS_SHAPER_UI.render(
        document.getElementById(NotificationsShaperId),
      );
    }
  }, []);

  const handleScriptInjectCB = useCallback((newState: any, addedTags: any) => {
    handleScriptInject(
      addedTags,
      toLowerCase(MODULES.NOTIFICATIONS_SHAPER),
      () => {
        setScriptIsLoaded(true);
        logHandleTime();
        if (window.NOTIFICATIONS_SHAPER_UI) {
          window.NOTIFICATIONS_SHAPER_UI.render(
            document.getElementById(NotificationsShaperId),
          );
        }
      },
    );
  }, []);

  const baseUrl = getApiUrl(Service.NS);
  const releasePostfix = useMemo(() => getReleasePostfix(), []);

  return (
    <>
      <Helmet
        script={
          scriptIsLoaded
            ? []
            : [
                {
                  src: `${baseUrl}${releasePostfix}/static/js/main.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href={`${baseUrl}${releasePostfix}/static/css/main.css`}
        />
      </Helmet>
      <div id={NotificationsShaperId} />
    </>
  );
};

export default NotificationsShaper;
