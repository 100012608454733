import { styled } from '@mui/material';
import { Select } from 'react-ui-kit-exante';

import { StyledProps } from '../../../shared/helpers/types';

export const StyledSelect = styled(Select)(({ theme }: StyledProps) => ({
  '& div.MuiInputBase-input, .MuiSelect-icon': {
    fontSize: 15,
    color: theme?.color?.typo?.ghost,
  },
  [`& div.MuiInputBase-root, 
    div.MuiSelect-select:hover, 
    div.MuiFilledInput-root:hover, 
    div.MuiSelect-select:focus, 
    div.MuiFilledInput-root:focus, 
    div.MuiFilledInput-root.Mui-disabled, 
    div.MuiFilledInput-root.Mui-focused`]: {
    backgroundColor: 'inherit',
  },
}));

export const LoaderContainer = styled('div')({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
});

export const IconContainer = styled('span')({
  display: 'block',
  width: 16,
  height: 16,
});

export const Icon = styled('img')({
  width: '100%',
  height: '100%',
});
