import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { TService } from '../auth/types';

const initialState = {
  serviceName: null,
};

const ServiceContext = createContext<{
  ctx: TInitialValues;
  updateCtx?: (mergeValues: any) => void;
}>({
  ctx: initialState,
});

type TInitialValues = {
  serviceName: TService | null;
};

interface IServiceProvider {
  children: ReactNode;
}

export const ServiceProvider: FC<React.PropsWithChildren<IServiceProvider>> = ({
  children,
}) => {
  const [values, setValues] = useState<TInitialValues>(initialState);

  const updateCtx = useCallback((mergeValues: any) => {
    setValues((prev) => ({ ...prev, ...mergeValues }));
  }, []);

  const context = useMemo(
    () => ({
      ctx: values,
      updateCtx,
    }),
    [values, updateCtx],
  );

  return (
    <ServiceContext.Provider value={context}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useServiceContext = () => useContext(ServiceContext);
