import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { QRCodeProps } from '../types';

const Canvas = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const Title = styled('div')(() => ({
  fontSize: '12px',
  fontWeight: 'bold',
  fontFamily: 'Inter',
  textAlign: 'center',
  lineHeight: '16px',
  marginBottom: '8px',
}));

export const QRCode: FC<React.PropsWithChildren<QRCodeProps>> = ({
  title = 'Scan the QR code into your app',
  refCallback,
}) => (
  <div>
    <Title>{title}</Title>
    <Canvas ref={refCallback} />
  </div>
);
