import axios, { AxiosResponse } from 'axios';

import {
  getBrandingConfig,
  initBranding,
} from '../shared/helpers/brandingHelpers';

import * as defaultBrandingConfig from './default-branding-config.json';
import { BrandingConfig } from './types';

export const fetchConfig = async () => {
  const { brandingUrl } = await getBrandingConfig();

  // For stage all WLs use exante's Branding Service
  try {
    const { data }: AxiosResponse<BrandingConfig> = await axios(brandingUrl);
    initBranding(data?.alias);
    const {
      apis,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      auth_flows = [],
      logo,
      menu,
      vars,
      modules,
      theme,
      features,
    } = data.runcfg;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return { auth_flows, apis, logo, menu, vars, modules, theme, features };
  } catch (err) {
    console.warn(
      `Could not find configuration file at ${brandingUrl}. Using fallback config`,
    );
    return (defaultBrandingConfig as any).default;
  }
};
