import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IAccessToken } from '../auth/types';

export const AUTH_API_REDUCER_KEY = 'authApi';

export const authApi = createApi({
  reducerPath: 'AUTH_API_REDUCER_KEY',
  baseQuery: (...props) => {
    const [args] = props;
    const { baseUrl } = args;

    return fetchBaseQuery({
      baseUrl,
    })(...props);
  },
  endpoints: (builder) => ({
    getTokens: builder.query<
      IAccessToken,
      { code: string | null; baseUrl: string }
    >({
      query: ({ code, baseUrl }) => ({
        baseUrl,
        credentials: 'include',
        url: `oauth/code?code=${code}`,
        headers: {
          Accept: 'application/json',
        },
      }),
    }),
  }),
});

export const { useGetTokensQuery } = authApi;
