import { ReactNode } from 'react';

import { AuthStatus, SERVICES } from './const';

export interface AuthLayoutProps {
  children?: ReactNode;
}

export interface AuthHeaderProps {
  title?: string;
}

export interface AuthLoginProps {
  error: string;
  title: string;
  type?: TokenTypes;
  onReturn?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  data?: I2FAAuthData;
}

export interface AuthLinkProps {
  children?: ReactNode;
  className?: string;
  href: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface AuthRestoreProps {
  handleClick?: () => Record<string, unknown>;
  title: string;
}

export enum FormState {
  AUTH,
  RESTORE,
  GA,
  SMS,
  EMAIL,
}

export type TService = typeof SERVICES[number];

export interface IServiceData {
  name: TService;
  tokenid: number | null;
  sessionid: string | null;
  status: AuthStatus | null;
  type: TokenTypes | null;
}

export interface IState {
  readonly errorAuth: string;
  readonly error2fA: string;
  readonly formState: FormState;
  readonly services: Array<IServiceData>;
}

export enum TokenTypes {
  GA = 'totp',
  SMS = 'sms',
  EMAIL = 'email',
}

export type TPayload = {
  name: string;
  sessionid?: string;
  tokenid?: number;
  status: AuthStatus;
  type?: TokenTypes;
};

export type TLocationState = {
  from: string | null;
};

export interface IAccessToken {
  access_token: string;
  expires_in: number;
  id_token: string;
}

export interface ITokenInfo {
  exp: number;
  legalEntities: string;
  services: string[];
  userid: number;
  username: string;
}

export type TToken = IAccessToken & ITokenInfo;

export interface I2FAAuthData {
  client_id: string;
  key: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  type: string;
  error?: string;
}
