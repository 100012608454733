import { useFormContext } from 'react-hook-form';

export const useDigitInput = (length: number) => {
  const { setValue, watch } = useFormContext();

  const handleInputChange = (value: string) => {
    setValue('code', value.trim());
  };

  const inputValue = watch('code');

  const REGEXP_PATTERN = `\\d{${length}}`;
  const REGEXP = new RegExp(REGEXP_PATTERN);

  const isFilled = REGEXP.test(inputValue);

  return {
    inputValue,
    isFilled,
    handleInputChange,
  };
};
