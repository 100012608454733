import { Switch } from 'react-ui-kit-exante';

import { Root, Header } from './SettingsSwitch.styled';

export const SettingsSwitch = ({
  handleOnChange,
  isChecked,
  title,
}: {
  handleOnChange: () => void;
  isChecked: boolean;
  title: string;
}) => {
  return (
    <Root>
      <Header>{title}</Header>
      <Switch label="" checked={isChecked} onChange={handleOnChange} />
    </Root>
  );
};
