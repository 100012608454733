import { startCase, camelCase } from 'lodash';
import { forwardRef, Ref } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbValueType } from 'react-ui-kit-exante';

import { MenuConfig, TCrmMenuItem } from '../../../config/types';
import { MenuItem } from '../../menu/types';
import { SERVICE_PATHS } from '../const';

const uidParamsLengths = new Set([32, 36, 56]);

const getRouterLink = (href: string): BreadcrumbValueType['routerLink'] =>
  // eslint-disable-next-line react/display-name
  forwardRef((linkProps, ref: Ref<HTMLAnchorElement>) => (
    <Link
      ref={ref}
      to={href}
      {...linkProps}
      style={{ textDecoration: 'none' }}
    />
  ));

export const getDefaultHomeCrumb = (url: string, HOME_PATH: string) => ({
  routerLink: getRouterLink(HOME_PATH),
  text: 'Home',
  disabled: url === HOME_PATH,
});

export const getUrlBasedLastCrumb = (url: string) => {
  const lastIndex = url.includes('=') ? url.indexOf('=') + 1 : url.length;
  const rawText = url.substring(url.lastIndexOf('/') + 1, lastIndex);

  const isDisabled =
    !rawText.includes(' ') &&
    !rawText.includes('@') &&
    uidParamsLengths.has(rawText.length);

  const text = startCase(camelCase(rawText.replaceAll('-', ' ')));
  const urlWithoutParams = url.substring(0, url.indexOf('=') + 1) || url;
  return {
    routerLink: getRouterLink(urlWithoutParams),
    text,
    disabled: isDisabled,
  };
};

const getBreadcrumbsStructure = (
  menuItem: MenuItem,
  url: string,
): BreadcrumbValueType[] => {
  const breadcrumbLink = menuItem.linkTo || menuItem.defaultLink;
  let result: BreadcrumbValueType[] = [];

  if (menuItem.isFavourite) {
    return result;
  }

  if (breadcrumbLink && menuItem.linkTo === url && !menuItem.isFavourite) {
    return [
      {
        text: menuItem.text,
        routerLink: getRouterLink(breadcrumbLink),
      },
    ];
  }

  if (menuItem.children) {
    menuItem.children.forEach((i: MenuItem) => {
      result = result.concat(getBreadcrumbsStructure(i, url));
    });
  }

  if (result.length > 0 && breadcrumbLink) {
    result.push({
      text: menuItem.text,
      routerLink: getRouterLink(breadcrumbLink),
    });
  }
  return result;
};

export const getBreadcrumbs = (
  menuConfig: MenuConfig,
  url: string,
): BreadcrumbValueType[] =>
  menuConfig.flatMap((i: any) => getBreadcrumbsStructure(i, url)).reverse();

export const cleanUrlFromServicePaths = (
  url: string,
  substringsToRemove: string | string[],
): string => {
  let modifiedUrl = url;

  // Ensure substringsToRemove is always an array
  const substringsArray =
    typeof substringsToRemove === 'string'
      ? [substringsToRemove]
      : substringsToRemove;

  substringsArray.forEach((substring) => {
    const adjustedSubstring = substring.endsWith('/')
      ? substring
      : `${substring}/`;
    modifiedUrl = modifiedUrl.replace(adjustedSubstring, '');
  });

  return modifiedUrl;
};

export const getUrl = (pathname: string, hash: string, search: string) => {
  const rawUrl = (hash + pathname).replace(/\/$/, '');
  const url = cleanUrlFromServicePaths(rawUrl, SERVICE_PATHS);

  if (url.includes('/bo')) {
    return url + search;
  }

  if (!url.includes('=')) {
    return url;
  }

  return url.substring(0, url.indexOf('=') + 1);
};

export const getUrlWithoutParams = (url: string) =>
  url.substring(0, url.lastIndexOf('/'));

export const getLastPartFromUrl = (url: string) =>
  url.substring(url.lastIndexOf('/'));

export const getUrlWithDefaultBookmark = (
  url: string,
  selectedBookmarkTab: any,
) => getUrlWithoutParams(url) + getLastPartFromUrl(selectedBookmarkTab.linkTo);

export const getBreadcrumbFromUrl = (url: string) => {
  const lastPart = getLastPartFromUrl(url);
  const urlWithoutLastCrumb = url.replace(lastPart, '');

  return decodeURIComponent(getLastPartFromUrl(urlWithoutLastCrumb) + lastPart);
};

export const getSelectedBookmarkTab = (
  bookmarks: TCrmMenuItem[],
  breadcrumbUrl: string,
) => {
  return bookmarks.find(
    (el) =>
      breadcrumbUrl === el.linkTo ||
      (el.children?.length &&
        el.children.some((child) => breadcrumbUrl.includes(child.linkTo))),
  );
};

export const getEnrichedBreadcrumbs = (
  url: string,
  menuConfig: MenuConfig,
  lastCrumb: BreadcrumbValueType,
  selectedBookmark?: TCrmMenuItem,
  selectedBookmarkTab?: TCrmMenuItem,
) => {
  let breadcrumbs = [];
  if (selectedBookmark) {
    breadcrumbs = getBreadcrumbs(
      menuConfig,
      getUrlWithDefaultBookmark(url, selectedBookmarkTab),
    );
    breadcrumbs.pop();
  } else {
    breadcrumbs = getBreadcrumbs(menuConfig, getUrlWithoutParams(url));
  }
  breadcrumbs.push(lastCrumb);
  return breadcrumbs;
};

export const getBookmarksBreadcrumbs = (selectedBookmarkTab?: TCrmMenuItem) => {
  let bookmarksBreadcrumbs;
  if (selectedBookmarkTab) {
    bookmarksBreadcrumbs = [selectedBookmarkTab];
    if (selectedBookmarkTab.children) {
      bookmarksBreadcrumbs = [
        ...bookmarksBreadcrumbs,
        ...selectedBookmarkTab.children,
      ];
    }
  }
  return bookmarksBreadcrumbs;
};
