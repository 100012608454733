export enum Headers {
  X_AUTH_SESSIONID = 'x-auth-sessionid',
  AUTHORIZATION = 'Authorization',
  CONTENT_TYPE = 'Content-Type',
}

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export enum Endpoint {
  Themes = '/themes',
  CurrencyHolidays = '/currency_holidays',
  Localizations = '/localizations',
  Currencies = '/currencies',
  Bookmarks = '/bookmarks',
  Brandings = '/brandings',
}
