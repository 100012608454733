import { styled } from '@mui/material/styles';
import { FC, ComponentType } from 'react';
import useDigitInput from 'react-digit-input';
import { TDefaultTheme } from 'react-ui-kit-exante';

import { DigitInputProps } from '../types';

import { DEFAULT_MARGIN, DEFAULT_WIDTH, DEFAULT_LENGTH } from './const';

const InputGroup = styled('div')(() => ({
  display: 'flex',
  margin: 'inherit -4px',
}));

interface IInputProps {
  length: number;
  width: number;
  theme?: TDefaultTheme;
}

const Input = styled('input')(({ theme, length, width }: IInputProps) => ({
  width: (width - DEFAULT_MARGIN * 2 * (length - 1)) / length,
  height: '48px',
  fontSize: '15px',
  fontFamily: theme?.font?.main,
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: theme?.color.typo.primary,
  outlineColor: theme?.color.typo.primary,
  boxSizing: 'border-box',
  margin: '0 4px',
  border: '1px solid #F2F1F1',
  borderRadius: '4px',
})) as ComponentType<React.PropsWithChildren<any>>;

export const DigitInput: FC<React.PropsWithChildren<DigitInputProps>> = ({
  value = '',
  onChange,
  dataTestId,
  length = DEFAULT_LENGTH,
  width = DEFAULT_WIDTH,
}) => {
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length,
    value,
    onChange,
  });

  return (
    <InputGroup data-test-id={dataTestId}>
      {[...Array(length).keys()].map((v, i) => (
        <Input
          key={v}
          inputMode="decimal"
          length={length}
          width={width}
          autoFocus={i === 0}
          {...digits[i]}
        />
      ))}
    </InputGroup>
  );
};
