import { useEffect, useMemo } from 'react';

import { fetchUserAuthflows } from '../../entities/user/reducer';
import { UserSelector, UserInfoSelector } from '../../entities/user/selectors';
import { IUserState } from '../../entities/user/types';
import { getMainToken } from '../../shared/helpers/apiHelpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAuthDBService } from '../services/AuthDB.service';

export const use2FA = () => {
  const dispatch = useAppDispatch();
  const authDBService = useAuthDBService();
  const { id: userId } = useAppSelector(UserInfoSelector);
  const { authflows, tokens } = useAppSelector<IUserState>(UserSelector);
  const mainToken = useMemo(() => getMainToken(tokens), [tokens]);

  const is2FAOn = useMemo(() => {
    return authflows?.some((authflow) => {
      return (
        authflow.firstStep === mainToken?.id &&
        authflow.secondSteps?.length !== 0
      );
    });
  }, [authflows, tokens]);

  const token2FA = useMemo(() => {
    const authFlow = authflows?.find(
      (authflow) => authflow.firstStep === mainToken?.id,
    );
    if (!authFlow) {
      return undefined;
    }

    const [secondStepTokenId] = authFlow.secondSteps;

    return tokens.find((token) => token.id === secondStepTokenId);
  }, [authflows, tokens]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserAuthflows({ userId, authDBService }));
    }
  }, [userId]);

  return {
    is2FAOn,
    token2FA,
  };
};
