import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Input } from 'react-ui-kit-exante';

import { useApi } from '../../../config/useApi';
import { useLogHandlePageLoadingTime } from '../../../hooks/useLogHandleTime';
import { useAppDispatch } from '../../../store/hooks';
import { authWithGoogle } from '../../services/Api/helpers';
import { AuthHeader } from '../AuthHeader/AuthHeader';
import { AuthFormValues, FormState } from '../const';
import { setFormState } from '../slices/authSlice';
import { AuthFormControlSX } from '../styles';
import { AuthLoginProps, TokenTypes } from '../types';

import { StyledAuthBackButton } from './AuthLoginMain.styled';

const renderLabel = (type?: TokenTypes) => {
  let label = '';

  switch (type) {
    case TokenTypes.EMAIL:
      label = 'Code email';
      break;
    case TokenTypes.SMS:
      label = 'Code SMS';
      break;
    case TokenTypes.GA:
    default:
      label = 'Google Authenticator';
      break;
  }
  return label;
};

export const AuthLogin2FA: FC<React.PropsWithChildren<AuthLoginProps>> = ({
  error,
  title,
  onReturn,
  type,
  data,
}) => {
  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
  } = useFormContext();
  const { startStartLoginTime } = useLogHandlePageLoadingTime();

  const dispatch = useAppDispatch();

  const { getAuthDBBaseUrl } = useApi();

  const handleClick = () => {
    startStartLoginTime();
    const baseUrl = getAuthDBBaseUrl();
    const { ga } = getValues();

    authWithGoogle(`${baseUrl}/auth/authenticate/2fa`, {
      ...data,
      code: ga,
    });

    dispatch(setFormState(FormState.AUTH));
  };

  return (
    <>
      <AuthHeader title={title} />
      <Controller
        name={AuthFormValues.GA}
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <Input
            fullWidth
            error={Boolean(error || errors?.ga?.message)}
            label={renderLabel(type)}
            sx={AuthFormControlSX}
            inputProps={{
              'data-test-id': 'authentication-module__input--2fa',
            }}
            {...field}
          />
        )}
      />
      <Button
        disabled={isSubmitting}
        data-test-id="authentication-module__button--2fa"
        fullWidth
        onClick={handleClick}
        sx={AuthFormControlSX}
        type="submit"
      >
        Login
      </Button>
      <StyledAuthBackButton
        iconName="ArrowLeftIcon"
        label="Back"
        iconSize={16}
        iconColor="secondary"
        onClick={onReturn}
      />
    </>
  );
};
