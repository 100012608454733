import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { FormState } from '../../const';

export const Form: FC<React.PropsWithChildren<any>> = ({
  children,
  defaultValues,
  schema,
}) => {
  const methods = useForm({
    defaultValues: {
      type: FormState.GA,
      ...defaultValues,
    },
    resolver: schema ? yupResolver(schema) : undefined,
  });

  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  );
};
