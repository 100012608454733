import { FC } from 'react';

import { StyledUserProfileName } from './UserProfileName.styled';
import { UserProfileNameProps } from './types';

export const UserProfileName: FC<
  React.PropsWithChildren<UserProfileNameProps>
> = ({ profileName, onClick }) => (
  <StyledUserProfileName
    iconName="ProfileIcon"
    iconColor="secondary"
    label={profileName}
    data-test-id="header-module__button--settings"
    onClick={onClick}
  />
);
