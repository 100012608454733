import { styled } from '@mui/material/styles';
import { ReactNode, Children } from 'react';

const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Label = styled('div')(() => ({
  fontSize: '13px',
  fontFamily: 'Inter, sans-serif',
  lineHeight: '32px',
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
}));

export const FormGroup = ({ children }: { children: ReactNode }) => (
  <Root>
    {Children.map(children, (child, index) => {
      // eslint-disable-next-line consistent-return
      return index === 0 && child !== null ? <Label>{child}</Label> : child;
    })}
  </Root>
);
