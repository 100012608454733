import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { schema } from './AuthForm.schema';
import { StyledAuthForm } from './AuthForm.styled';
import { AuthFormProps } from './types';

export const AuthForm: FC<React.PropsWithChildren<AuthFormProps>> = ({
  children,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <StyledAuthForm
        className="AuthForm"
        onSubmit={(event) => event.preventDefault()}
      >
        {children}
      </StyledAuthForm>
    </FormProvider>
  );
};
