import QRCode from 'qrcode';
import { useCallback, useState } from 'react';

import { QRCodeWidth } from './const';
import { getQrString } from './helpers';

export const useQRCode = (userName: string) => {
  const [key, setKey] = useState<string>('');

  const refCallback = useCallback(
    async (node: any): Promise<void> => {
      if (node !== null) {
        const { uri, uniqId } = getQrString(userName);
        setKey(uniqId);

        try {
          const canvas = await QRCode.toCanvas(uri, {
            width: QRCodeWidth,
          });

          node.appendChild(canvas);
        } catch (err: any) {
          console.error(err);
        }
      }
    },
    [userName],
  );

  return {
    refCallback,
    key,
  };
};
