import { createListenerMiddleware } from '@reduxjs/toolkit';
import { capitalize } from 'lodash';
import { Notification } from 'react-ui-kit-exante';

import {
  AuthStatus,
  NotificationMessage,
  NotificationStatus,
  SERVICES,
} from '../components/auth/const';
import { setServiceData } from '../components/auth/slices/authSlice';
import { TPayload } from '../components/auth/types';

import { IServiceCounter } from './types';

export const setServiceDataListener = createListenerMiddleware();

window.SERVICE_NOTIFICATION_COUNTER = [];

setServiceDataListener.startListening({
  actionCreator: setServiceData,
  effect: (action) => {
    const payload: TPayload = action.payload;
    const { name, status } = payload;

    if (window?.SERVICE_NOTIFICATION_COUNTER.length !== SERVICES.length) {
      window.SERVICE_NOTIFICATION_COUNTER.push({ name, status });
    }

    if (window?.SERVICE_NOTIFICATION_COUNTER.length === SERVICES.length) {
      const { fail, success, ga } = window.SERVICE_NOTIFICATION_COUNTER.reduce(
        (acc: IServiceCounter, next) => {
          const notification = capitalize(next.name);

          if (next.status === AuthStatus.OK) {
            acc.success.push(notification);
          } else if (next.status === AuthStatus.UNAUTHORIZED) {
            acc.fail.push(notification);
          } else if (next.status === AuthStatus['2FA']) {
            acc.ga.push(notification);
          }
          return acc;
        },
        { success: [], fail: [], ga: [] },
      );

      window.SERVICE_NOTIFICATION_COUNTER = [];

      if (success.length !== 0) {
        Notification[NotificationStatus.OK]({
          title: `${NotificationMessage.OK}\n${success.join('\n')}`,
        });
      }
      if (ga.length !== 0) {
        Notification[NotificationStatus['2FA']]({
          title: `${NotificationMessage['2FA']}\n${ga.join('\n')}`,
        });
      }
      if (fail.length === SERVICES.length) {
        Notification[NotificationStatus.UNAUTHORIZED]({
          title: `${NotificationMessage.PASSWORD}\n${ga.join('\n')}`,
        });
      }
    }
  },
});
