import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { TDefaultTheme } from 'react-ui-kit-exante';

const heads = [
  'M20 20.8333C23.6819 20.8333 26.6667 17.8486 26.6667 14.1667C26.6667 10.4848 23.6819 7.5 20 7.5C16.3181 7.5 13.3334 10.4848 13.3334 14.1667C13.3334 17.8486 16.3181 20.8333 20 20.8333Z',
  'M20 35C21.6569 35 23 33.6569 23 32C23 30.3431 21.6569 29 20 29C18.3431 29 17 30.3431 17 32C17 33.6569 18.3431 35 20 35Z',
];

export const ProfileSvg = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const theme: TDefaultTheme = useTheme();
  const { color } = theme;

  const lineColors = useMemo(
    () => [color?.typo?.secondary, color?.typo?.action],
    [theme],
  );

  const animationProps = useSpring({
    head: heads[activeIndex],
    lineColor: lineColors[activeIndex],
    arrowOpacity: activeIndex ? 1 : 0,
  });

  const handleMouseEnter = useCallback(() => {
    setActiveIndex(1);
  }, []);

  const handleMouseOver = useCallback(() => {
    setActiveIndex(0);
  }, []);

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOver}
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="white"
      />
      <animated.path
        d="M17.375 2.38157C18.9994 1.44375 21.0006 1.44375 22.625 2.38157L33.9455 8.91747C35.5699 9.85529 36.5705 11.5885 36.5705 13.4641V26.5359C36.5705 28.4115 35.5699 30.1447 33.9455 31.0825L22.625 37.6184C21.0006 38.5563 18.9994 38.5563 17.375 37.6184L6.05449 31.0825C4.43014 30.1447 3.42949 28.4115 3.42949 26.5359V13.4641C3.42949 11.5885 4.43014 9.85529 6.05449 8.91747L17.375 2.38157Z"
        fill="white"
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
      />
      <animated.path
        d="M33.3333 31.6667C33.3333 29.8986 32.6309 28.2029 31.3807 26.9526C30.1304 25.7024 28.4347 25 26.6666 25H13.3333C11.5652 25 9.86949 25.7024 8.61925 26.9526C7.369 28.2029 6.66663 29.8986 6.66663 31.6667"
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d={animationProps.head}
        stroke={animationProps.lineColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M19.9646 21.1356V9"
        stroke="#007F39"
        strokeOpacity={animationProps.arrowOpacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <animated.path
        d="M15 13.9646L19.9646 9L24.9291 13.9646"
        stroke="#007F39"
        strokeOpacity={animationProps.arrowOpacity}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
