import { useAuthDBService } from '../../../components/services/AuthDB.service';
import { getMainToken } from '../../../shared/helpers/apiHelpers';

export const transformInput = (value?: string) =>
  !value || value.startsWith('+') ? value : `+${value}`;

export const transformOutput = (value: string) =>
  value && value.startsWith('+') ? value.slice(1) : value || '';

export const changeUserPassword = async (
  newPassword: string,
  userId: number,
  authDBService: ReturnType<typeof useAuthDBService>,
) => {
  try {
    const info = await authDBService.getUserInfo(userId);
    const mainToken = getMainToken(info?.tokens);

    return await authDBService.updateToken(
      userId,
      mainToken?.id as number,
      newPassword,
    );
  } catch (error: any) {
    throw new Error(error);
  }
};
