import { useCallback, useRef } from 'react';

import { useNodeBackService } from '../components/services/NodeBack.service';

const startLoginTimeKey = 'last-login-btn-click';

export function useLogHandleTime(entity: string) {
  const start = useRef<string | null>(null);

  const setStartHandleTimeFromLogin = useCallback(() => {
    const startLoginTime = localStorage.getItem(startLoginTimeKey);

    if (start.current === null && startLoginTime) {
      start.current = startLoginTime;
      localStorage.removeItem(startLoginTimeKey);

      return start.current;
    }

    return null;
  }, []);

  const setStartHandleTime = useCallback(() => {
    if (start.current === null) {
      start.current = new Date().toISOString();
    }
  }, []);

  const { fetchLogHandleTime } = useNodeBackService();

  const logHandleTime = useCallback(() => {
    if (start.current) {
      fetchLogHandleTime({
        entity,
        start: start.current,
        end: new Date().toISOString(),
      });

      start.current = null;
    }
  }, []);

  return {
    setStartHandleTime,
    logHandleTime,
    setStartHandleTimeFromLogin,
  };
}

export function useLogHandlePageLoadingTime(pathname = '') {
  const {
    setStartHandleTimeFromLogin: setStartHandleTimeWithLogin,
    logHandleTime: logHandleTimeWithLogin,
  } = useLogHandleTime(`render-after-login-${pathname}`);

  const logHandlePageLoadingTime = () => {
    const loginClickTime = setStartHandleTimeWithLogin();
    if (loginClickTime) {
      logHandleTimeWithLogin();
    }
  };

  const startStartLoginTime = () => {
    localStorage.setItem(startLoginTimeKey, new Date().toISOString());
  };

  return {
    startStartLoginTime,
    logHandlePageLoadingTime,
  };
}
