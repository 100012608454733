import { useCallback, useEffect, useState } from 'react';
import { Switch, Notification, Tooltip } from 'react-ui-kit-exante';

import { ReactComponent as AppleIcon } from '../../../../../assets/i/Apple.svg';
import { ReactComponent as GoogleIcon } from '../../../../../assets/i/Google.svg';
import InfoIcon from '../../../../../assets/i/Info.svg';
import { authWithGoogle } from '../../../../../components/services/Api/helpers';
import { useAuthDBService } from '../../../../../components/services/AuthDB.service';
import { useApi } from '../../../../../config/useApi';
import { IAccountData } from '../../types';

import { Root, ProviderName, SwitchWrapper } from './AccountSwitch.styled';

export const PROVIDER_NAME = {
  appleid: {
    name: 'apple',
    icon: AppleIcon,
  },
  google: {
    name: 'google',
    icon: GoogleIcon,
  },
};

export const AccountSwitch = ({
  providerName,
  data,
  userId,
}: {
  providerName: 'google' | 'appleid';
  data: IAccountData;
  userId: number | null;
}) => {
  const [isBound, setIsBound] = useState(false);
  const [bindingId, setBindingId] = useState<typeof data.id | null>(null);
  const authDBService = useAuthDBService();
  const { getAuthDBBaseUrl } = useApi();

  useEffect(() => {
    if (data.id) {
      setBindingId(data.id);
      setIsBound(true);
    }
  }, [data]);

  const onChange = useCallback(
    async (event: any, isChecked: boolean) => {
      try {
        if (!isChecked && bindingId && userId) {
          await authDBService.deleteUserExternalBinding(userId, bindingId);
          setIsBound(false);
          setBindingId(null);
          Notification.success({
            title: `The user is successfully unbound with ${PROVIDER_NAME[providerName].name} account`,
          });
        }
        if (userId && isChecked) {
          authWithGoogle(`${getAuthDBBaseUrl()}/auth/authenticate`, {
            login_hint: providerName,
          });
          setIsBound(true);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [bindingId, userId, providerName],
  );

  const connectionStatus = (isOn: boolean) => (isOn ? 'connect' : 'disconnect');
  const Icon = PROVIDER_NAME[providerName].icon;
  return (
    <Root>
      <ProviderName>
        <Icon width={16} height={16} />
        {PROVIDER_NAME[providerName].name}
      </ProviderName>
      <SwitchWrapper>
        <Tooltip
          title={`Your ${
            PROVIDER_NAME[providerName].name
          } account is ${connectionStatus(
            isBound,
          )}ed, switch it on to ${connectionStatus(!isBound)} it.`}
          placement="top"
        >
          <img alt="info" src={InfoIcon} />
        </Tooltip>
        <Switch label="" checked={isBound} onChange={onChange} />
      </SwitchWrapper>
    </Root>
  );
};
