import { styled } from '@mui/material/styles';
import { IconButton } from 'react-ui-kit-exante';

import { StyledProps } from '../../../shared/helpers/types';

export const StyledAuthLoginLoaderWrapper = styled('div')(
  ({ theme }: StyledProps) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme?.color.bg.basic,
  }),
);

export const StyledAuthLoginControls = styled('div')`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 360px;
  }
`;

export const StyledExternalAuthControls = styled('div')`
  display: flex;
  gap: 24px;
`;
export const StyledAuthBackButton = styled(IconButton)`
  align-self: flex-start;
`;

export const LoginControlsTitle = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: 'Inter, sans-serif',
  textAlign: 'center',
  width: '100%',
  color: theme?.color.typo.secondary,
  position: 'relative',
  '&:before': {
    content: "''",
    left: 0,
    right: 0,
    backgroundColor: theme?.color?.dropdown?.border,
    height: '1px',
    position: 'absolute',
    top: '50%',
  },
}));

export const LoginControlsTitleContent = styled('span')(
  ({ theme }: StyledProps) => ({
    position: 'relative',
    backgroundColor: theme?.color?.bg?.primary,
    fontSize: '12px',
    lineHeight: '16px',
    padding: '8px',
  }),
);
