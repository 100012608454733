import { styled } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

import ProfileSettingsStyles from '../../ProfileSettings.module.css';
import { SettingsFormValues } from '../../const';
import {
  IProfilePasswordControls,
  ProfileSettingsFormValues,
} from '../../types';
import { CloseSvg } from '../ProfileInputField/CloseSvg/CloseSvg';
import { SaveSvg } from '../ProfileInputField/SaveSvg/SaveSvg';

const Root = styled('div')(() => ({
  width: '100%',
}));

const Fields = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '& > *:first-child': {
    marginBottom: '8px',
  },
}));

const Controls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > *:first-child': {
    marginRight: '8px',
  },
}));

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#818C99',
  fontSize: '12px',
  marginBottom: '8px',
}));

const Icon = styled('div')(() => ({
  cursor: 'pointer',
}));

export const ProfilePasswordControls: FC<
  React.PropsWithChildren<IProfilePasswordControls>
> = ({ handleClose, handleSave }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useFormContext<ProfileSettingsFormValues>();

  const onSave = (event: any) => {
    event.stopPropagation();

    if (typeof handleSave === 'function') {
      handleSubmit(handleSave)();
    }
  };

  const onClose = () => {
    if (typeof handleClose === 'function') {
      handleClose();
    }
  };

  return (
    <Root>
      <Header>
        <div>PASSWORD</div>
        <Controls>
          <Icon
            data-test-id="settings-module__button--password-change-save"
            onClick={onSave}
          >
            <SaveSvg className={ProfileSettingsStyles.IconLarge} />
          </Icon>
          <Icon
            data-test-id="settings-module__button--password-change-cancel"
            onClick={onClose}
          >
            <CloseSvg className={ProfileSettingsStyles.IconLarge} />
          </Icon>
        </Controls>
      </Header>
      <Fields>
        <Controller<ProfileSettingsFormValues>
          defaultValue=""
          name={SettingsFormValues.OLD_PASSWORD}
          control={control}
          render={({ field }) => (
            <Input
              autoComplete="new-password"
              fullWidth
              error={Boolean(errors?.oldPassword?.message)}
              type="password"
              placeholder="New password"
              size="small"
              inputProps={{
                'data-test-id': `settings-module__input--${SettingsFormValues.NEW_PASSWORD}`,
              }}
              {...field}
            />
          )}
          shouldUnregister
        />
        <Controller<ProfileSettingsFormValues>
          defaultValue=""
          name={SettingsFormValues.NEW_PASSWORD}
          control={control}
          render={({ field }) => (
            <Input
              autoComplete="new-password"
              fullWidth
              error={Boolean(errors?.newPassword?.message)}
              type="password"
              placeholder="Repeat password"
              helperText={errors?.newPassword?.message}
              inputProps={{
                'data-test-id': `settings-module__input--${SettingsFormValues.CONFIRM_NEW_PASSWORD}`,
              }}
              size="small"
              {...field}
            />
          )}
          shouldUnregister
        />
      </Fields>
    </Root>
  );
};
