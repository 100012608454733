import { useState } from 'react';
import { useTimer } from 'use-timer';

export const useMessage = () => {
  const [message, setMessage] = useState('');

  const { start } = useTimer({
    endTime: 0,
    initialTime: 3,
    timerType: 'DECREMENTAL',
    onTimeOver: () => {
      setMessage('');
    },
  });

  const showMessage = (msg: string) => {
    setMessage(msg);
    start();
  };

  return {
    message,
    showMessage,
  };
};
