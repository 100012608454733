import { styled } from 'react-ui-kit-exante';

export const StyledAuthHeader = styled('h1')`
  margin: 0 0 24px;

  color: ${({ theme }) => theme.color.typo.primary};

  font-family: ${({ theme }) => theme.font.header};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;

  user-select: none;
`;
