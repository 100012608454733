import { createSlice } from '@reduxjs/toolkit';

import { OAuthState } from './types';

const initialState: OAuthState = {
  services: [],
};

export const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
    setServiceData: (state, action) => ({
      ...state,
      services: action.payload,
    }),
    setOauthInitialState: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const { setServiceData, setOauthInitialState } = oauthSlice.actions;
