import { styled } from 'react-ui-kit-exante';

export const StyledAppVersion = styled('div')`
  padding: 16px 24px;

  color: ${({ theme }) => theme.color.typo.secondary};

  font-family: ${({ theme }) => theme.font.main};
  font-size: 11px;
  line-height: 16px;
`;
