import { createSlice } from '@reduxjs/toolkit';

export type TBadges = Record<string, number> | null;
export type GlobalSearchState = {
  crm: {
    badges: TBadges;
    query?: string;
  };
};
const initialState: GlobalSearchState = {
  crm: {
    badges: null,
    query: '',
  },
};

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setCrmBadgesState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.crm.badges = action.payload;
    },
    clearCrmBadgesState: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.crm.badges = null;
    },
    setCrmQueryState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.crm.query = action.payload;
    },
  },
});

export const globalSearchReducer = globalSearchSlice.reducer;

export const { setCrmBadgesState, setCrmQueryState, clearCrmBadgesState } =
  globalSearchSlice.actions;
