import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { Service, useApi } from '../../config/useApi';
import { useLogHandleTime } from '../../hooks/useLogHandleTime';
import { toLowerCase } from '../../shared/toLowerCase';
import { MODULES } from '../shared/const';
import { useScriptInject } from '../shared/helpers';

import { getRoute } from './utils';

declare const window: any;

const Cprm = () => {
  const [crmScriptsLoaded, setScriptLoaded] = useState(false);
  const location = useLocation();
  const { getApiUrl } = useApi();
  const element = document.getElementsByClassName('Content')[0];
  const { handleScriptInject } = useScriptInject();
  window.CPRM_UI.setScriptLoaded = setScriptLoaded;

  const { setStartHandleTime, logHandleTime } = useLogHandleTime('cprm-bundle');

  setStartHandleTime();

  useEffect(() => {
    if (crmScriptsLoaded && window.CPRM_UI && window.CPRM_UI.render) {
      window.CPRM_UI.render(document.getElementById('cprm'));
    }
  }, [crmScriptsLoaded]);

  useEffect(() => {
    if (window.CPRM_UI && window.CPRM_UI.goto) {
      window.CPRM_UI.goto(getRoute(location), element);
    }
  }, [element, location]);

  const handleScriptInjectCB = useCallback((_newState: any, addedTags: any) => {
    handleScriptInject(addedTags, toLowerCase(MODULES.CPRM), () => {
      logHandleTime();
      if (window.CPRM_UI && window.CPRM_UI.render) {
        window.CPRM_UI.render(document.getElementById('cprm'));
      }
    });
  }, []);
  const baseUrl = getApiUrl(Service.Cprm);
  const baseAPIUrl = getApiUrl(Service.CprmApi);
  return (
    <>
      <Helmet
        script={
          crmScriptsLoaded
            ? []
            : [
                {
                  src: `${baseUrl}/js/app.js`,
                },
                {
                  src: `${baseAPIUrl}/clientsarea/static/django_js_reverse/js/reverse.js`,
                },
                {
                  src: `${baseUrl}/js/libphonenumber/libphonenumber-bundle.js`,
                },
                {
                  src: `${baseUrl}/js/PhoneFormat.js`,
                },
                {
                  src: `${baseUrl}/js/chunk-vendors.js`,
                },
              ]
        }
        onChangeClientState={handleScriptInjectCB}
      >
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,800,700,600,400"
        />
        <link rel="stylesheet" href={`${baseUrl}/css/dropzone.css`} />
        <link rel="stylesheet" href={`${baseUrl}/css/datepicker.css`} />
        <link rel="stylesheet" href={`${baseUrl}/css/vex.css`} />
        <link rel="stylesheet" href={`${baseUrl}/css/vex-theme-exante.css`} />
        <link rel="stylesheet" href={`${baseUrl}/css/vex.css`} />
        <link
          rel="stylesheet"
          href={`${baseUrl}/css/vex-theme-exante-clientsarea.css`}
        />
        <link rel="stylesheet" href={`${baseUrl}/css/vex-theme-plain.css`} />
        <link
          rel="stylesheet"
          href={`${baseUrl}/css/vue-multiselect.min.css`}
        />
        <link rel="stylesheet" href={`${baseUrl}/css/crm.min.css`} />
        <link rel="stylesheet" href={`${baseUrl}/css/app.css`} />
        <link rel="stylesheet" href={`${baseUrl}/css/chunk-vendors.css`} />{' '}
      </Helmet>
      <div id="cprm" className="Cprm" />
    </>
  );
};

export default Cprm;
