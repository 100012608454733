import { Chip as ChipMUI, List as ListMUI } from '@mui/material';
import { styled } from '@mui/material/styles';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { StyledProps } from '../../shared/helpers/types';

import { COLLAPSED_MENU_WIDTH, MENU_WIDTH } from './const';
import { CollapseMenuButtonProps, ListProps, MenuProps } from './types';

export const StyledMenuWrapper = styled('aside', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})(({ isCollapsed }: MenuProps) => ({
  flex: `0 0 ${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  width: `${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  transition: 'flex-basis .4s ease-out, width .4s ease-out',
  height: '100vh',
  userSelect: 'none',
}));

export const StyledMenu = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})(({ isCollapsed, theme }: MenuProps) => ({
  position: 'fixed',
  userSelect: 'none',
  width: `${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  height: '100vh',

  backgroundColor: theme?.color.bg.primary,
  zIndex: 2,
  border: 'none',
  overflowY: 'auto',
  overflowX: isCollapsed ? 'hidden' : 'visible',
  transition: 'width .4s ease-out',

  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px ${theme?.color.bg.basic}`,
    background: theme?.color.bg.primary,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme?.color.bg.secondary,
  },
}));

export const StyledMenuList = styled(ListMUI, {
  shouldForwardProp: blockNonNativeProps(['isMainMenuList']),
})(({ isMainMenuList }: ListProps) => ({
  ...(isMainMenuList && { paddingBottom: '56px' }),
  paddingTop: 0,
  pointerEvents: 'all',
  width: `calc(${MENU_WIDTH}px - 0.5em)`,
  '& .MenuButtonApprovals': {
    position: 'relative',
    display: 'block',

    '& .ApprovalsChip': {
      position: 'absolute',
      top: 10,
      right: 16,
    },
  },
}));

export const Chip = styled(ChipMUI)(({ theme }: StyledProps) => ({
  border: `1.5px solid ${theme?.color?.typo?.action}`,
  borderRadius: '32px',
  backgroundColor: 'transparent',
  height: '24px',
  '.MuiChip-label': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    color: theme?.color?.typo?.action,
  },
}));

export const SmallChip = styled(ChipMUI)(({ theme }: StyledProps) => ({
  borderRadius: '24px',
  border: `1px solid ${theme?.color?.typo?.action}`,
  margin: '0 2px',
  color: theme?.color?.typo.action,
  backgroundColor: 'transparent',
  height: '17px',
  '.MuiChip-label': {
    padding: '0 5px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    color: theme?.color?.typo?.action,
  },
}));

export const CollapseMenuButton = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})(({ theme, isCollapsed }: CollapseMenuButtonProps) => ({
  cursor: 'pointer',
  position: 'fixed',
  height: 56,
  width: `${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  bottom: 0,
  color: theme?.color?.typo.secondary,
  backgroundColor: theme?.color?.bg?.primary,
  backgroundRepeat: 'no-repeat',
  boxShadow: `0 -1px 3px ${theme?.color?.bg?.basic}`,
  border: 0,

  '&:hover': {
    color: theme?.color?.typo.primary,
  },

  '.CollapseIcon': {
    transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)',
    transition: 'transform .4s ease-out',
    position: 'absolute',
    top: 16,
    left: 16,
  },
}));
