import { styled } from '@mui/material';

import { StyledProps } from '../../../../../shared/helpers/types';

export const Controls = styled('div')(({ theme }: StyledProps) => () => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginRight: '4px',
  color: theme?.color.typo.primary,
}));
