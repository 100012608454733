import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  radioGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: '16px',
  },
});
