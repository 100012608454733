import * as yup from 'yup';

import { AuthFormValues } from '../const';

export const schema = yup.object().shape(
  {
    username: yup
      .string()
      .required()
      .when(AuthFormValues.GA, {
        is: (val: string) => val !== undefined,
        then: yup.string().notRequired(),
      }),
    password: yup
      .string()
      .required()
      .when(AuthFormValues.GA, {
        is: (val: string) => val !== undefined,
        then: yup.string().notRequired(),
      }),
    ga: yup
      .string()
      .notRequired()
      .when([AuthFormValues.LOGIN, AuthFormValues.PASSWORD], {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
  },
  [
    [AuthFormValues.LOGIN, AuthFormValues.PASSWORD],
    [AuthFormValues.GA, AuthFormValues.LOGIN],
    [AuthFormValues.GA, AuthFormValues.PASSWORD],
  ],
);
