import { MenuConfig, TCrmMenuItem } from '../../config/types';

import { DashboardBookmark } from './types';

export const getCRMBookmarksMenuItems = (
  menuConfig: MenuConfig,
  serviceName: string,
  bookmarks?: TCrmMenuItem[],
) => {
  if (!bookmarks) {
    return [];
  }
  return menuConfig.reduce((acc: DashboardBookmark[], menuItem) => {
    if (!menuItem.children) {
      return acc;
    }

    const bookmarkTabs = bookmarks.filter(
      (bookmark) =>
        bookmark.children &&
        bookmark.children.length &&
        menuItem.children &&
        menuItem.children.some(
          (child) => child.crmId === `${serviceName}-${bookmark.id}`,
        ),
    );

    if (bookmarkTabs.length) {
      acc.push({ ...menuItem, text: menuItem.text, bookmarkTabs, serviceName });
    }
    return acc;
  }, []);
};

export const getBoBookmarksMenuItems = (
  menuConfig: MenuConfig,
  serviceName: string,
  bookmarks?: TCrmMenuItem[],
) => {
  if (!bookmarks) {
    return [];
  }
  return menuConfig.reduce((acc: DashboardBookmark[], menuItem) => {
    if (
      !menuItem.children ||
      (!menuItem.linkTo?.includes('bo') &&
        !menuItem.defaultLink?.includes('bo'))
    ) {
      return acc;
    }

    const bookmarkTabs = bookmarks.filter(
      (bookmark) =>
        bookmark.children?.length &&
        menuItem.children?.some((child) =>
          (child.linkTo || child.defaultLink)?.includes(bookmark.linkTo),
        ),
    );

    if (bookmarkTabs.length) {
      acc.push({ ...menuItem, text: menuItem.text, bookmarkTabs, serviceName });
    }
    return acc;
  }, []);
};
