import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTransition, animated } from 'react-spring';
import { Loader, TDefaultTheme } from 'react-ui-kit-exante';

import { ReactComponent as SaveDisabledIcon } from '../../../../../assets/i/SaveDisabled.svg';
import ProfileSettingsStyles from '../../ProfileSettings.module.css';
import { SettingsFormValues } from '../../const';
import { IProfileNameEdit, ProfileSettingsFormValues } from '../../types';
import { FormGroup } from '../FormGroup/FormGroup';
import { CloseSvg } from '../ProfileInputField/CloseSvg/CloseSvg';
import { EditSvg } from '../ProfileInputField/EditSvg/EditSvg';
import { InputField } from '../ProfileInputField/InputField';
import {
  Icon,
  IconSave,
  Root,
  Value,
} from '../ProfileInputField/ProfileInputField.styled';
import { SaveSvg } from '../ProfileInputField/SaveSvg/SaveSvg';
import { useField } from '../useField';

import { Controls } from './ProfileNameEdit.styled';
import { ProfileSvg } from './ProfileSvg/ProfileSvg';

const name = SettingsFormValues.FULL_NAME;

export const ProfileNamesEdit: FC<
  React.PropsWithChildren<IProfileNameEdit>
> = ({
  firstName,
  lastName,
  onSave,
  setEditField,
  editField,
  isClose = true,
}) => {
  const theme: TDefaultTheme = useTheme();
  const { canEdit, isOpen, handleEdit, handleSave, isWaiting } = useField({
    name,
    setEditField,
    editField,
    onSave,
  });

  const {
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    clearErrors,
  } = useFormContext<ProfileSettingsFormValues>();

  const watchFirstNameValue: string = watch(SettingsFormValues.FIRST_NAME);
  const watchLastNameValue: string = watch(SettingsFormValues.LAST_NAME);
  const isDirty =
    dirtyFields[SettingsFormValues.FIRST_NAME] || SettingsFormValues.LAST_NAME;

  const fullName = `${watchFirstNameValue} ${watchLastNameValue}`;

  const handleClose = () => {
    if (typeof setEditField === 'function') {
      setEditField(null);
    }

    clearErrors(name);

    setValue('firstName', firstName, {
      shouldDirty: true,
    });

    setValue('lastName', lastName, {
      shouldDirty: true,
    });
  };

  const transitionProps = {
    from: { opacity: 0, transform: 'translateX(30%)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0, transform: 'translateX(30%)' },
  };

  const activeTransitions = useTransition(
    isOpen && canEdit && !isWaiting,
    transitionProps,
  );

  const Active = activeTransitions(
    (styles, isShow) =>
      isShow && (
        <animated.div style={{ width: '100%', ...styles }}>
          <Root>
            <Controls>
              <InputField
                control={control}
                dirtyFields={dirtyFields}
                errors={errors}
                name={SettingsFormValues.FIRST_NAME}
                value={firstName}
                handleSave={handleSave}
              />
              <InputField
                control={control}
                dirtyFields={dirtyFields}
                errors={errors}
                name={SettingsFormValues.LAST_NAME}
                value={lastName}
                handleSave={handleSave}
              />
              <IconSave
                data-test-id={`settings-module__button--save-${name}`}
                isDisabled={!isDirty}
                onClick={(event) => handleSave(event, name)}
              >
                {isDirty ? (
                  <SaveSvg />
                ) : (
                  <SaveDisabledIcon stroke={theme?.color.typo.action} />
                )}
              </IconSave>
            </Controls>

            {isClose && (
              <Icon
                data-test-id={`settings-module__button--close-${name}`}
                onClick={handleClose}
              >
                <CloseSvg className={ProfileSettingsStyles.Icon} />
              </Icon>
            )}
          </Root>
        </animated.div>
      ),
  );

  const Inactive = (
    <Root>
      <Value isWaiting={canEdit && isWaiting} isLarge>
        {fullName}
      </Value>
      <Icon
        isClickable={canEdit && !isWaiting}
        onClick={canEdit && !isWaiting ? handleEdit : undefined}
      >
        {canEdit &&
          (!isWaiting ? (
            <EditSvg
              className={ProfileSettingsStyles.Icon}
              dataTestId={`settings-module__button--edit-${name}`}
            />
          ) : (
            <Loader />
          ))}
      </Icon>
    </Root>
  );

  return (
    <FormGroup>
      <ProfileSvg />
      {isOpen && canEdit && !isWaiting ? Active : Inactive}
    </FormGroup>
  );
};
