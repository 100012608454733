import React, { useEffect } from 'react';

import { CRM_SERVICE } from '../auth/const';

import { appendIframe, getIframe } from './utils';

const CrmDjangoAdmin = () => {
  useEffect(() => {
    if (window.CRM_UI?.api_urls.CRM) {
      // TODO Move to branding
      const adminUrl = window.CRM_UI.api_urls.CRM.replace('backend-', '');
      const el = document.getElementsByClassName('CrmAdmin')[0];
      const iframe = getIframe(el, `${adminUrl}/django-admin/`);
      appendIframe(iframe, CRM_SERVICE, window.CRM_UI.api_urls.CRM);
    }
  }, [window.CRM_UI]);

  return <div className="CrmAdmin" />;
};

export default CrmDjangoAdmin;
