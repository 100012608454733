import { capitalize } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { FC, useContext, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { TDefaultTheme } from 'react-ui-kit-exante';

import { useAuthContext } from '../../../components/auth/AuthProvider';
import { StatusCodes } from '../../../components/auth/const';
import { use2FA } from '../../../components/profile/use2FA';
import { Routes } from '../../../components/router/types';
import { useAuthDBService } from '../../../components/services/AuthDB.service';
import { ConfigContext } from '../../../config/ConfigContext';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  DARK_THEME,
  LIGHT_THEME,
  LOCAL_STORAGE_KEY_COLOR_SCHEME,
} from '../../../theme/const';
import { TColorScheme } from '../../../theme/types';
import { changeUserInfo } from '../reducer';
import { UserInfoSelector } from '../selectors';
import { IUserState } from '../types';

import { detailsSchema } from './ProfileSettings.schema';
import { ExternalAccounts } from './components/ExternalAccounts/ExternalAccounts';
import { Form } from './components/Form/Form';
import { Message } from './components/Message/Message';
import { useMessage } from './components/Message/useMessage';
import { ProfileEmailView } from './components/ProfileEmailView/ProfileEmailView';
import { ProfileNamesEdit } from './components/ProfileNameEdit/ProfileNameEdit';
import { ProfilePasswordEdit } from './components/ProfilePasswordEdit/ProfilePasswordEdit';
import { ProfilePhoneEdit } from './components/ProfilePhoneEdit/ProfilePhoneEdit';
import { SettingsSwitch } from './components/SettingsSwitch/SettingsSwitch';
import { SettingsFormValues } from './const';
import { changeUserPassword } from './helpers';
import { ProfileSettingsFormValues } from './types';

interface IProps {
  anchorEl: any;
  handleClose: any;
}

const useStyles = makeStyles((theme: TDefaultTheme) => ({
  card: {
    backgroundColor: theme?.color.modal.bg,
    '&::before': {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: `7px solid ${theme?.color.modal.bg}`,
      position: 'absolute',
      top: '-6px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  popover: {
    overflow: 'visible',
    width: '360px',
    borderRadius: '4px',
  },
  content: {
    padding: '16px',
    boxSizing: 'border-box',
    color: theme?.color.typo.primary,
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
}));

export const ProfileSettings: FC<React.PropsWithChildren<IProps>> = ({
  anchorEl,
  handleClose,
}) => {
  const authDBService = useAuthDBService();
  const { card, content, popover } = useStyles();
  const { getItem, setItem } = useLocalStorage();
  const [userPassword, setUserPassword] = useState('');
  const { message, showMessage } = useMessage();
  const history = useHistory();
  const { logout } = useAuthContext();
  const [editField, setEditField] = useState<string | null>(null);
  const [colorScheme, setColorScheme] = useState<TColorScheme>(() => {
    return (
      (getItem(LOCAL_STORAGE_KEY_COLOR_SCHEME) as TColorScheme) || LIGHT_THEME
    );
  });
  const { features } = useContext(ConfigContext);

  const dispatch = useAppDispatch();

  const {
    id: userId,
    primaryPhone,
    userName,
    firstName,
    lastName,
  } = useAppSelector<IUserState['info']>(UserInfoSelector);

  const { is2FAOn } = use2FA();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleUserNameSubmit = async (values: ProfileSettingsFormValues) => {
    try {
      await dispatch(
        changeUserInfo({
          ...values,
          authDBService,
        }),
      );

      showMessage('Successfully changed');
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleSubmit: SubmitHandler<ProfileSettingsFormValues> = async (
    values,
    methods: any,
  ) => {
    const { newPassword } = values;

    try {
      if (!userId || editField === null) {
        return;
      }

      switch (editField) {
        case SettingsFormValues.PHONE:
          await dispatch(
            changeUserInfo({
              ...values,
              authDBService,
            }),
          );
          break;
        case SettingsFormValues.PASSWORD:
          await changeUserPassword(
            (newPassword as string) || userPassword,
            userId,
            authDBService,
          );
          setUserPassword('');
          logout();
          break;
        default:
          return;
      }

      showMessage(`${capitalize(editField)} successfully changed`);
    } catch (error: any) {
      const response = JSON.parse(error?.message);

      if (
        response?.status === StatusCodes.UNAUTHORIZED &&
        typeof methods?.setError === 'function'
      ) {
        methods.setError(SettingsFormValues.CODE, {
          type: 'manual',
          message: response?.statusText,
        });
      }
      console.error(response?.statusText);
    }
  };

  const messageTransitions = useTransition(Boolean(message), {
    from: { opacity: 0, maxHeight: 0 },
    enter: { opacity: 1, maxHeight: 50 },
    leave: { opacity: 0, maxHeight: 0 },
    config: {
      duration: 250,
    },
  });

  const handleSwitchColorScheme = () => {
    const newColorScheme =
      colorScheme === DARK_THEME ? LIGHT_THEME : DARK_THEME;
    setItem(LOCAL_STORAGE_KEY_COLOR_SCHEME, newColorScheme);
    setColorScheme(newColorScheme);
    window.location.reload();
  };

  const update2FA = () => {
    history.push(Routes.PROFILE);
  };

  return (
    <Popover
      id={id}
      classes={{ paper: popover }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      marginThreshold={50}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Card classes={{ root: card }}>
        <Form
          defaultValues={{
            firstName,
            lastName,
            email: userName,
            phone: primaryPhone,
            password: '********',
          }}
          schema={detailsSchema}
        >
          <CardContent classes={{ root: content }}>
            <ProfileNamesEdit
              firstName={firstName}
              lastName={lastName}
              setMessage={showMessage}
              onSave={handleUserNameSubmit}
              editField={editField}
              setEditField={setEditField}
            />
            <ProfileEmailView userName={userName} />
            <ProfilePhoneEdit
              phone={primaryPhone}
              handleSubmit={handleSubmit}
              editField={editField}
              setEditField={setEditField}
            />
            <ProfilePasswordEdit
              handleSubmit={handleSubmit}
              editField={editField}
              setEditField={setEditField}
            />
            {!features?.disableLoginViaCredentials && <ExternalAccounts />}
            {features?.enableTheme && (
              <SettingsSwitch
                isChecked={colorScheme === DARK_THEME}
                handleOnChange={handleSwitchColorScheme}
                title="dark theme"
              />
            )}
            <SettingsSwitch
              isChecked={is2FAOn}
              handleOnChange={update2FA}
              title="2-Step Authentication"
            />
            {messageTransitions(
              (styles, isShow) =>
                isShow && (
                  <animated.div style={styles}>
                    <Message message={message} />
                  </animated.div>
                ),
            )}
          </CardContent>
        </Form>
      </Card>
    </Popover>
  );
};
