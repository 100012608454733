import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import { MigrationManifest } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';

import { FormState, SERVICES } from '../components/auth/const';
import { authSlice } from '../components/auth/slices/authSlice';
import { oauthSlice } from '../components/auth/slices/oauthSlice';
import { globalSearchReducer } from '../components/globalSearch/reducer';
import { menuSlice } from '../components/menu/reducer';
import { settingsReducer } from '../components/profile/reducer';
import { authApi } from '../components/services/AuthApi.service';
import { configSlice } from '../config/configSlice';
import { userReducer } from '../entities/user/reducer';

import { setServiceDataListener } from './middlewares';

const reducers = combineReducers({
  settings: settingsReducer,
  user: userReducer,
  globalSearch: globalSearchReducer,
  [menuSlice.name]: menuSlice.reducer,
  // slices
  [authSlice.name]: authSlice.reducer,
  [oauthSlice.name]: oauthSlice.reducer,
  [configSlice.name]: configSlice.reducer,
  // api
  [authApi.reducerPath]: authApi.reducer,
});

const migrations: MigrationManifest = {
  0: (state: RootState) => ({
    ...state,
    auth: {
      ...state.auth,
      error2fA: '',
      errorAuth: '',
      formState: FormState.AUTH,
      services: SERVICES.map((name) => ({
        name,
        tokenid: null,
        sessionid: null,
        status: null,
        type: null,
      })),
    },
  }),
  1: (state: RootState) => ({
    ...state,
    user: {
      ...state.user,
      auths: [],
    },
  }),
  // remove settings from whitelist
  2: (state: RootState) => ({
    ...state,
  }),
  3: (state: RootState) => ({
    ...state,
  }),
  4: (state: RootState) => ({
    ...state,
    menu: {
      ...state.menu,
      config: [],
    },
  }),
  5: (state: RootState) => ({
    ...state,
    menu: {
      ...state.menu,
      crm: {
        ...state.menu.crm,
        menu: [],
      },
      cprm: {
        ...state.menu.cprm,
        menu: [],
      },
    },
  }),
};

const rootPersistConfig = {
  key: 'root',
  storage,
  version: 5,
  whitelist: [authSlice.name, oauthSlice.name, 'user', menuSlice.name],
  migrate: createMigrate(migrations),
};

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, reducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      setServiceDataListener.middleware,
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
