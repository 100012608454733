import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTimer } from 'use-timer';

import { useAuthDBService } from '../../services/AuthDB.service';
import { EndTimeValue, FormState, TimerValue } from '../const';
import { getToken, sendConfirmationCode } from '../helpers';
import { TwoFASettingsCardProps } from '../types';

import { CardCover } from './components/CardCover/CardCover';

export const TwoFASettingsCard: FC<
  React.PropsWithChildren<TwoFASettingsCardProps>
> = ({
  Component,
  active,
  dataTestId,
  onClick,
  handleClose,
  icon,
  phone,
  text,
  title,
  token,
  value,
  userName,
  userId,
}) => {
  const { setValue } = useFormContext();
  const authDBService = useAuthDBService();

  const isActive = active === value;

  const { time, start, status } = useTimer({
    endTime: EndTimeValue,
    initialTime: TimerValue,
    timerType: 'DECREMENTAL',
  });

  const [isSentOnce, setSentOnce] = useState(false);

  const isTimerRunning = status === 'RUNNING';

  const sendCode = async () => {
    start();

    try {
      const { mainTokenId, tokenid, url } = await sendConfirmationCode(
        authDBService,
        userId,
        value,
      );

      setValue('url', url);
      setValue('tokenId', tokenid);
      setValue('type', value);
      setValue('mainTokenId', mainTokenId);
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!token && value !== FormState.GA && isActive && !isSentOnce) {
      setSentOnce(true);
      sendCode();
    }
  }, [isActive, token, value, isSentOnce]);

  useEffect(() => {
    if (!token || !isActive || isTimerRunning || isSentOnce) {
      return;
    }

    if (value !== FormState.GA) {
      start();
    }

    (async () => {
      try {
        const { mainTokenId, tokenId, url } = await getToken(
          authDBService,
          userId,
          value,
        );

        setValue('url', url);
        setValue('tokenId', tokenId);
        setValue('mainTokenId', mainTokenId);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [isActive, isTimerRunning, token]);

  return isActive ? (
    <Component
      buttonText={token ? 'Disable' : 'Verify'}
      isTimerRunning={isTimerRunning}
      start={start}
      time={time}
      handleClose={handleClose}
      token={token}
      userName={userName}
      phone={phone}
      sendCode={sendCode}
      userId={userId}
    />
  ) : (
    <CardCover
      icon={icon}
      dataTestId={dataTestId}
      onClick={onClick}
      value={value}
      title={title}
      text={text}
      token={token}
    />
  );
};
