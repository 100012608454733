/* eslint-disable react/jsx-key */
import { ListItem as ListItemMUI } from '@mui/material';
import { ListItemProps as ListItemPropsMUI } from '@mui/material/ListItem/ListItem';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import {
  ComponentType,
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelGroup } from 'react-ui-kit-exante';

import { useBOBookmarks } from '../../config/useBOBookmarks';
import { useCrmCprmBookmarks } from '../../config/useCrmCprmBookmarks';
import { useMenuConfig } from '../../config/useMenuConfig';
import { UserInfoSelector } from '../../entities/user/selectors';
import { isExante } from '../../shared/helpers/routingHelpers';
import { StyledProps } from '../../shared/helpers/types';
import { useAppSelector } from '../../store/hooks';

import { getBoBookmarksMenuItems, getCRMBookmarksMenuItems } from './helpers';

export const Name = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.header,
  color: theme?.color?.typo?.primary,
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '48px',
  marginBottom: '8px',
}));

export const Greeting = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.main,
  color: theme?.color?.typo?.promo,
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '24px',
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  width: '100%',
}));

export const Bookmarks = styled('div')(() => ({
  display: 'flex',
  gridGap: '24px',
}));

export const Bookmark = styled('div')(() => ({
  maxWidth: '240px',
}));

export const BookmarkTitle = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.header,
  color: theme?.color?.typo?.primary,
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '24px',
  userSelect: 'none',
}));

export const BookmarkSubTitle = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.main,
  color: theme?.color?.typo?.ghost,
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '24px',
  userSelect: 'none',
  textTransform: 'uppercase',
  marginTop: '16px',
}));

type ListItemProps = ListItemPropsMUI & {
  component: ReactNode;
};

const ListItem: ComponentType<React.PropsWithChildren<ListItemProps>> = styled(
  ListItemMUI,
)(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.main,
  color: theme?.color?.typo?.action,
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '24px',
  marginTop: '8px',
  padding: 0,
  '&:hover': {
    color: theme?.color?.typo?.promo,
  },
}));

export const Dashboard = () => {
  const { nameSurname } = useAppSelector(UserInfoSelector);
  const { crmBookmarks, cprmBookmarks } = useCrmCprmBookmarks();
  const { boBookmarks, requestBoBookmarks } = useBOBookmarks();
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();

  const crmBookmarkMenuItems = useMemo(
    () => getCRMBookmarksMenuItems(menuConfig, 'crm', crmBookmarks),
    [crmBookmarks, menuConfig],
  );
  const cprmBookmarkMenuItems = useMemo(
    () => getCRMBookmarksMenuItems(menuConfig, 'cprm', cprmBookmarks),
    [cprmBookmarks, menuConfig],
  );
  const boBookmarkMenuItems = useMemo(
    () => getBoBookmarksMenuItems(menuConfig, 'bo', boBookmarks),
    [boBookmarks, menuConfig],
  );

  const bookmarkMenuItems = [
    ...boBookmarkMenuItems,
    ...crmBookmarkMenuItems,
    ...cprmBookmarkMenuItems,
  ];

  useEffect(() => {
    requestBoBookmarks(true);
  }, []);

  return (
    <PanelGroup panelsPerLine={1} equalWidth>
      <Panel>
        <Greeting>
          <Name>Hello, {nameSurname}</Name>
          Welcome to {isExante() && 'EXANTE'} RUN
        </Greeting>
      </Panel>
      {!isEmpty(bookmarkMenuItems) && (
        <Panel title="Bookmarks">
          <Bookmarks>
            {bookmarkMenuItems.map((item) => (
              <Bookmark key={item.defaultLink || item.linkTo}>
                <BookmarkTitle>{item.text}</BookmarkTitle>
                {item.bookmarkTabs.map((tab) => {
                  return (
                    <div key={tab.linkTo}>
                      <BookmarkSubTitle>{tab.text}</BookmarkSubTitle>
                      {[tab, ...(tab.children ? tab.children : [])].map(
                        (bookmark) => {
                          const CustomLink =
                            // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
                            forwardRef(
                              (linkProps, ref: Ref<HTMLAnchorElement>) => {
                                const link = bookmark.linkTo || '';

                                return (
                                  <Link
                                    key={bookmark.linkTo}
                                    ref={ref}
                                    to={link}
                                    {...linkProps}
                                    style={{ textDecoration: 'none' }}
                                  />
                                );
                              },
                            );

                          // eslint-disable-next-line no-constant-condition
                          const rootNode = bookmark.linkTo
                            ? CustomLink
                            : undefined;

                          return (
                            <ListItem
                              component={rootNode as ReactNode}
                              key={bookmark.linkTo}
                              style={{ textDecoration: 'none' }}
                            >
                              {bookmark.text}
                            </ListItem>
                          );
                        },
                      )}
                    </div>
                  );
                })}
              </Bookmark>
            ))}
          </Bookmarks>
        </Panel>
      )}
    </PanelGroup>
  );
};
