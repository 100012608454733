import { TColorScheme } from '../theme/types';

type LocalStorage = {
  currency: string;
  lastPage: string;
  connectToken: string;
  connectProvider: string;
  colorScheme: TColorScheme;
};

export const useLocalStorage = () => {
  const setItem = <T extends keyof LocalStorage>(
    key: T,
    value: LocalStorage[T],
  ) => localStorage.setItem(key, value);

  const getItem = (key: keyof LocalStorage) => localStorage.getItem(key);

  const removeItem = (key: keyof LocalStorage) => localStorage.removeItem(key);

  return {
    setItem,
    getItem,
    removeItem,
  };
};
