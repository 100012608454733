import { AxiosInstance } from 'axios';
import qs from 'qs';

import { I2FAAuthData } from '../../auth/types';

import { Headers } from './constants';

export const paramsSerializer = (params: Record<string, unknown>) =>
  qs.stringify(params, {
    arrayFormat: 'comma',
    encode: false,
    skipNulls: true,
  });

export function getCleanUrl() {
  const {
    location: { protocol, host, pathname },
  } = window;
  return `${protocol}//${host}${pathname}`;
}

export const submitFormWithPost = (
  url: string,
  data: Record<string, string>,
) => {
  const form = document.createElement('form');
  form.target = '_self';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  Object.keys(data).forEach((key) => {
    const input = document.createElement('input');
    input.type = 'text';
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const authWithGoogle = (url: string, data: Record<string, string>) => {
  return submitFormWithPost(url, {
    ...data,
    response_type: 'code',
    client_id: 'checkpoint',
    redirect_uri: getCleanUrl(),
  });
};

export const openWindowWithPost = async (url: string, data: any) => {
  const { headers, ...requestData } = data;
  const requestHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded',
    ...headers,
  };

  const formData = new URLSearchParams(requestData);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: requestHeaders,
      body: formData,
    });

    const json: I2FAAuthData = await response.json();
    return json;
  } catch (error) {
    authWithGoogle(url, requestData);
    return null;
  }
};

export const authWithLoginPassword = (
  url: string,
  data: Record<string, string>,
) => {
  return openWindowWithPost(url, {
    ...data,
    response_type: 'code',
    client_id: 'checkpoint',
    redirect_uri: getCleanUrl(),
    headers: {
      Accept: 'application/json',
    },
  });
};

export const setHeaders = (
  instance: AxiosInstance,
  sessionToken?: string,
  accessToken?: string,
) => {
  if (accessToken) {
    // eslint-disable-next-line no-param-reassign
    instance.defaults.headers[Headers.AUTHORIZATION] = `Bearer ${accessToken}`;
  } else {
    // eslint-disable-next-line no-param-reassign
    delete instance.defaults.headers[Headers.AUTHORIZATION];
  }
};
