import { styled, Typography } from '@mui/material';
import { ComponentType } from 'react';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { IValueProps, IIconProps, IIconSaveProps } from './types';

export const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
}));

export const Value = styled(Typography, {
  shouldForwardProp: blockNonNativeProps(['isWaiting', 'isLarge']),
})(({ isWaiting, isLarge }: IValueProps) => ({
  marginRight: '8px',
  fontSize: isLarge ? '15px' : '13px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: isWaiting ? 'normal' : 'bold',
  color: isWaiting ? '#B8BBB7' : 'inherit',
})) as ComponentType<React.PropsWithChildren<IValueProps>>;

export const Icon = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isClickable']),
})(({ isClickable = true }: IIconProps) => ({
  cursor: isClickable ? 'pointer' : 'auto',
  width: '16px',
  height: '16px',
})) as ComponentType<React.PropsWithChildren<IIconProps>>;

export const IconSave = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isDisabled']),
})(({ isDisabled }: IIconSaveProps) => ({
  display: 'flex',
  cursor: isDisabled ? 'text' : 'pointer',
  pointerEvents: isDisabled ? 'none' : 'auto',
  width: '16px',
  height: '16px',
})) as ComponentType<React.PropsWithChildren<IIconSaveProps>>;

export const IconAdornment = styled(IconSave)(() => ({
  position: 'absolute',
  transform: 'translateY(-50%)',
  top: '50%',
  right: '16px',
  height: '16px',
}));

export const PhoneControl = styled('div')(() => ({
  position: 'relative',
}));
