import { useMemo, useState } from 'react';

import { useNodeBackService } from '../components/services/NodeBack.service';
import {
  TCrmBookmarksData,
  TBOMenuItem,
} from '../components/services/Types/bookmarks.types';

import { getBookmarksDistributedByTabs } from './helpers';
import { MenuItem } from './types';
import { useMenuConfig } from './useMenuConfig';

let cachedBOBookmarksData: TCrmBookmarksData = null;

export const useBOBookmarks = () => {
  const { getBookmarks } = useNodeBackService();
  const { getMenuConfig } = useMenuConfig();

  const menuConfig = getMenuConfig();

  const [boBookmarksState, setBOBookmarksStateState] =
    useState<TBOMenuItem[]>();

  const getBOBookmarkData = async (forceUpdate = false) => {
    if (!cachedBOBookmarksData || forceUpdate) {
      cachedBOBookmarksData = await getBookmarks();
    }
    return cachedBOBookmarksData;
  };

  const getUniqueBookmarkTabs = (bookmarks: (string | undefined)[]) => {
    const uniqueTabs = new Set();

    bookmarks.forEach((bookmark) => {
      uniqueTabs.add(bookmark);
    });

    return Array.from(uniqueTabs);
  };

  const addBookmarkTabs = (bookmarks: TBOMenuItem[]) => {
    const bookmarkTabs = bookmarks.map((b) => b.bookmarkTab);
    const newBookmarks = [...bookmarks];

    if (Array.isArray(bookmarkTabs)) {
      const commonTabs = getUniqueBookmarkTabs(bookmarkTabs) as string[];

      commonTabs.forEach((tab) => {
        let item: MenuItem;

        let result: MenuItem | undefined;
        menuConfig.find((menuItem) => {
          if (menuItem?.children) {
            result = menuItem?.children.find(
              (child) =>
                (child.linkTo || child.defaultLink)?.includes(tab) &&
                (child.linkTo || child.defaultLink)?.includes('bo'),
            );
            if (result) {
              item = result;
            }

            return result;
          }
          return result;
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (item) {
          const linkTo = (item?.linkTo || item.defaultLink)?.split('/');
          if (linkTo) {
            linkTo.splice(1, 1);

            newBookmarks.push({
              id: tab,
              linkTo: linkTo.join('/'),
              text: item.text,
            });
          }
        }
      });
    }

    return newBookmarks;
  };

  const request = async (forceUpdate = false) => {
    try {
      const resp = await getBOBookmarkData(forceUpdate);
      if (resp && resp.data) {
        const addedTabs = addBookmarkTabs(resp.data);
        setBOBookmarksStateState(
          getBookmarksDistributedByTabs(addedTabs, 'bo'),
        );
      }
    } catch (e) {
      console.error('BO bookmarks error', e);
    }
  };

  return useMemo(
    () => ({
      boBookmarks: boBookmarksState,
      requestBoBookmarks: request,
    }),
    [boBookmarksState],
  );
};
