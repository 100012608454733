import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CenteredLoader, Notification, SearchInput } from 'react-ui-kit-exante';

import { CrmBookmarkItem } from '../../config/types';
import { useMenuConfig } from '../../config/useMenuConfig';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useServices } from '../auth/hooks/useServices';
import { CrmBookmarksSelector } from '../menu/selectors';
import { MenuItem } from '../menu/types';
import { useCprmService } from '../services/Cprm.service';
import { useCrmService } from '../services/Crm.service';
import { useShaperService } from '../services/Shaper.service';

import {
  INPUT_SEARCH_DELAY,
  MAX_RESULT_SEARCH,
  MIN_RESULT_SEARCH,
  MIN_SEARCH_LENGTH,
} from './GlobalSearch.consts';
import GlobalSearchStyles from './GlobalSearch.module.css';
import {
  ItemStyled,
  LoaderStyled,
  ResultPopupStyled,
  IconWithTextStyled,
  TitleStyled,
  SearchResultStyled,
  StyledChip,
} from './GlobalSearch.styled';
import {
  CRMSearchResult,
  MenuSearchResult,
  TabsResultsCount,
} from './GlobalSearch.types';
import { getSearchResults, mergeInTurn } from './helpers';
import {
  clearCrmBadgesState,
  setCrmBadgesState,
  setCrmQueryState,
} from './reducer';

let cancelToken: CancelTokenSource;

export const GlobalSearch = () => {
  const dispatch = useAppDispatch();
  const { getBookmarksListReq, requestCrmSearch } = useCrmService();
  const { requestCprmSearch } = useCprmService();
  const { requestShaperSearch } = useShaperService();
  const history = useHistory();
  const bookmarksData = useAppSelector(CrmBookmarksSelector);
  const { allowedServiceNames } = useServices();
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();

  const [searchData, setSearchData] = useState<MenuSearchResult[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef(null);
  useOutsideClick(ref, () => setIsFocused(false));

  const onSearchBookmarks = async (
    query: string,
    bookmarks: CrmBookmarkItem[],
  ) => {
    try {
      const bookmarksBadges = await Promise.all(
        bookmarks.map(({ id }) => getBookmarksListReq(id, query)),
      );
      return bookmarksBadges.reduce(
        (acc, curr) => ({ ...acc, [curr.url]: curr.count }),
        {},
      );
    } catch (error: any) {
      Notification.error({
        title: error?.message,
      });
      return {};
    }
  };

  const setSearchResults = (
    allowedMenuItems: MenuItem[],
    consolidatedData: { result: CRMSearchResult[]; tabs: TabsResultsCount },
    value: string,
  ) => {
    const { searchResults, menuSearchBadgeCount } = getSearchResults(
      menuConfig,
      consolidatedData,
      value,
    );
    setSearchData(searchResults);

    return {
      menuSearchBadgeCount,
    };
  };

  const onSearch = async (value: string) => {
    try {
      const initialData = { count: 0, result: [], tabs: {} };
      setIsLoading(true);
      setSearchResults(menuConfig, initialData, value);
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel();
      }
      cancelToken = axios.CancelToken.source();

      // TODO: optimize when the number of services to search becomes more than 2
      const services = [
        {
          name: 'crm',
          request: () => requestCrmSearch(value, cancelToken),
        },
        {
          name: 'CPRM',
          request: () => requestCprmSearch(value, cancelToken),
        },
        {
          name: 'notifications',
          request: () => requestShaperSearch(value, cancelToken),
        },
      ];

      const requests = services
        .filter((service) => allowedServiceNames.includes(service.name))
        .map((service) => service.request());

      const results = await Promise.allSettled(requests);

      const filteredResults = results.filter(
        (result) => result.status !== 'rejected',
      );

      const consolidatedData = filteredResults
        .map((result) => {
          if (result?.status && result?.status !== 'rejected') {
            return result?.value?.data;
          }
          return result;
        })
        .reduce((acc, curr) => {
          Object.entries(curr.tabs).forEach(([key, val]) => {
            if (!acc.tabs[key]) {
              acc.tabs[key] = 0;
            }
            acc.tabs[key] += val;
          });

          return {
            ...acc,
            count: acc.count + curr.count,
            result: mergeInTurn<MenuSearchResult>(acc.result, curr.result),
          };
        }, initialData);

      const { menuSearchBadgeCount } = setSearchResults(
        menuConfig,
        consolidatedData,
        value,
      );
      dispatch(setCrmQueryState(value));
      const searchDataCount = consolidatedData?.count || MIN_RESULT_SEARCH;

      if (
        searchDataCount > MIN_RESULT_SEARCH &&
        searchDataCount <= MAX_RESULT_SEARCH &&
        bookmarksData?.data
      ) {
        const bookmarksBadges = await onSearchBookmarks(
          value,
          bookmarksData.data,
        );
        dispatch(
          setCrmBadgesState({
            ...menuSearchBadgeCount,
            ...bookmarksBadges,
            ...consolidatedData?.tabs,
          }),
        );
      } else {
        dispatch(
          setCrmBadgesState({
            ...menuSearchBadgeCount,
            ...consolidatedData?.tabs,
          }),
        );
      }

      if (window.CRM_UI?.search) {
        window.CRM_UI.search(value);
      }
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        Notification.error({
          title: error?.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClearSearch = () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel();
    }
    setSearchData(null);
    dispatch(clearCrmBadgesState());
    dispatch(setCrmQueryState(''));
    if (window.CRM_UI?.search) {
      window.CRM_UI.search('');
    }
  };
  const search = debounce((e: string) => {
    if (e.length > MIN_SEARCH_LENGTH) {
      onSearch(e);
    } else {
      onClearSearch();
    }
  }, INPUT_SEARCH_DELAY);

  const onSelectItem = (link?: string) => {
    setIsFocused(false);
    if (link) {
      history.push(link);
    }
  };

  const isShowPopup = (searchData || isLoading) && isFocused;

  return (
    <div className={GlobalSearchStyles.Container} ref={ref}>
      <SearchInput
        placeholder="Search"
        onChange={search}
        inputProps={{
          onFocus: () => setIsFocused(true),
          fullWidth: true,
        }}
      />
      {isShowPopup && (
        <div className={GlobalSearchStyles.PopupContainer}>
          <ResultPopupStyled className={GlobalSearchStyles.ResultContainer}>
            {isLoading && (
              <LoaderStyled>
                <CenteredLoader isInner size="m" />
              </LoaderStyled>
            )}
            {searchData?.map(
              ({
                link,
                title,
                icon,
                resultsCount,
                crmResults,
                shaperResults,
                menuResults,
              }: MenuSearchResult) => {
                return (
                  <ItemStyled key={title}>
                    <TitleStyled
                      title={title}
                      onClick={() => onSelectItem(link)}
                    >
                      <IconWithTextStyled>
                        <img alt={title} src={icon} width="24" />
                        {title}
                      </IconWithTextStyled>
                      {resultsCount > 0 && (
                        <StyledChip
                          variant="outlined"
                          size="small"
                          label={resultsCount}
                        />
                      )}
                    </TitleStyled>
                    <div>
                      {menuResults?.map((el) => (
                        <SearchResultStyled
                          key={`${el.linkTo}-${el.text}`}
                          onClick={() =>
                            onSelectItem(el.linkTo || el.defaultLink)
                          }
                        >
                          {el.text}
                        </SearchResultStyled>
                      ))}
                      {crmResults?.map((el) => (
                        <SearchResultStyled
                          key={el.link}
                          onClick={() => onSelectItem(`/crm/${el.link}`)}
                        >
                          {el.email}
                        </SearchResultStyled>
                      ))}
                      {shaperResults?.map((el) => (
                        <SearchResultStyled
                          key={`${el.link}-${el.snippet}`}
                          onClick={() => onSelectItem(`/ns${el.link}`)}
                        >
                          {el.snippet}
                        </SearchResultStyled>
                      ))}
                    </div>
                  </ItemStyled>
                );
              },
            )}
          </ResultPopupStyled>
        </div>
      )}
    </div>
  );
};
