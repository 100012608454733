import { useTheme } from '@mui/material/styles';
import cn from 'classnames';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { useTransition, animated } from 'react-spring';
import { Input, Loader, TDefaultTheme } from 'react-ui-kit-exante';

import { ReactComponent as SaveDisabledIcon } from '../../../../../assets/i/SaveDisabled.svg';
import ProfileSettingsStyles from '../../ProfileSettings.module.css';
import { SettingsFormValues } from '../../const';
import { transformInput, transformOutput } from '../../helpers';
import { IProfileInputField, ProfileSettingsFormValues } from '../../types';
import { ProfilePasswordControls } from '../ProfilePasswordEdit/ProfilePasswordControls';
import { useField } from '../useField';

import { CloseSvg } from './CloseSvg/CloseSvg';
import { EditSvg } from './EditSvg/EditSvg';
import {
  PhoneControl,
  IconSave,
  Icon,
  IconAdornment,
  Root,
  Value,
} from './ProfileInputField.styled';
import { SaveSvg } from './SaveSvg/SaveSvg';
import { IControllerProps } from './types';

const ControllerEnhanced: FC<React.PropsWithChildren<IControllerProps>> = ({
  Component,
  control,
  transform,
  name,
  defaultValue,
  ...rest
}) => (
  <Controller
    defaultValue={defaultValue}
    control={control}
    name={name}
    render={({ field }) => (
      <Component
        {...field}
        onChange={(value: string) => field.onChange(transform.output(value))}
        value={transform.input(field.value)}
        {...rest}
      />
    )}
  />
);

export const ProfileInputField: FC<
  React.PropsWithChildren<IProfileInputField>
> = ({ onSave, editField, setEditField, name, value, isClose = true }) => {
  const { canEdit, isOpen, handleEdit, isWaiting, handleSave } = useField({
    name,
    setEditField,
    editField,
    onSave,
  });

  const theme: TDefaultTheme = useTheme();

  const {
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    clearErrors,
  } = useFormContext<ProfileSettingsFormValues>();

  const hasError = Boolean(errors[name]?.message);

  const watchValue = watch(name);

  const isDirty = dirtyFields[name];

  const phoneCls = cn(ProfileSettingsStyles.Phone, {
    [ProfileSettingsStyles.Error]: hasError,
  });

  const handleClose = () => {
    if (typeof setEditField === 'function') {
      setEditField(null);
    }

    clearErrors(name);

    setValue(name, value, {
      shouldDirty: true,
    });
  };

  let fieldComponent: any;

  switch (name) {
    case SettingsFormValues.PHONE:
      fieldComponent = (
        <PhoneControl>
          <ControllerEnhanced
            Component={PhoneInput}
            name={SettingsFormValues.PHONE}
            control={control}
            defaultValue={value}
            transform={{
              input: transformInput,
              output: transformOutput,
            }}
            className={phoneCls}
            placeholder="Enter phone number"
            error={String(hasError)}
            data-test-id={`settings-module__input--${SettingsFormValues.PHONE}`}
          />
          <IconAdornment
            isDisabled={!isDirty}
            onClick={(event) => handleSave(event, name)}
            data-test-id={`settings-module__button--save-${name}`}
          >
            {isDirty ? (
              <SaveSvg />
            ) : (
              <SaveDisabledIcon stroke={theme?.color.typo.action} />
            )}
          </IconAdornment>
        </PhoneControl>
      );
      break;
    case SettingsFormValues.PASSWORD:
      fieldComponent = (
        <ProfilePasswordControls
          handleClose={handleClose}
          handleSave={handleSave}
        />
      );
      break;
    default:
      fieldComponent = (
        <Controller
          defaultValue={value}
          control={control}
          name={name}
          render={({ field }) => (
            <Input
              iconRight={
                <IconSave
                  data-test-id={`settings-module__button--save-${name}`}
                  isDisabled={!isDirty}
                  onClick={(event) => handleSave(event, name)}
                >
                  {isDirty ? (
                    <SaveSvg />
                  ) : (
                    <SaveDisabledIcon stroke={theme?.color.typo.action} />
                  )}
                </IconSave>
              }
              inputProps={{
                'data-test-id': `settings-module__input--${name}`,
              }}
              error={hasError}
              size="small"
              sx={{
                mr: '8px',
              }}
              {...field}
            />
          )}
        />
      );
  }

  const Inactive = (
    <Root>
      <Value
        isWaiting={canEdit && isWaiting}
        isLarge={name === 'firstName' || name === 'lastName'}
      >
        {watchValue}
      </Value>
      <Icon
        isClickable={canEdit && !isWaiting}
        onClick={canEdit && !isWaiting ? handleEdit : undefined}
      >
        {canEdit &&
          (!isWaiting ? (
            <EditSvg
              className={ProfileSettingsStyles.Icon}
              dataTestId={`settings-module__button--edit-${name}`}
            />
          ) : (
            <Loader />
          ))}
      </Icon>
    </Root>
  );

  const transitionProps =
    name === SettingsFormValues.PASSWORD
      ? {
          from: { opacity: 0, maxHeight: 0 },
          enter: { opacity: 1, maxHeight: 180 },
          leave: { opacity: 0, maxHeight: 0 },
          config: {
            duration: 500,
          },
        }
      : {
          from: { opacity: 0, transform: 'translateX(30%)' },
          enter: { opacity: 1, transform: 'translateX(0)' },
          leave: { opacity: 0, transform: 'translateX(30%)' },
        };

  const activeTransitions = useTransition(
    isOpen && canEdit && !isWaiting,
    transitionProps,
  );

  const Active = activeTransitions(
    (styles, isShow) =>
      isShow && (
        <animated.div style={{ width: '100%', ...styles }}>
          <Root>
            {fieldComponent}
            {isClose && (
              <Icon
                data-test-id={`settings-module__button--close-${name}`}
                onClick={handleClose}
              >
                <CloseSvg className={ProfileSettingsStyles.Icon} />
              </Icon>
            )}
          </Root>
        </animated.div>
      ),
  );

  return isOpen && canEdit && !isWaiting ? Active : Inactive;
};
