import { last } from 'lodash';

import { TBadges } from '../globalSearch/reducer';
import { isSdbInnerModule } from '../shared/helpers';

import { MenuItem as MenuItemType, MenuItem } from './types';

export const getFormattedItemText = (item: MenuItem, parent?: MenuItem) => {
  const parentText = parent ? `-${parent.text}` : '';
  const childText = `--${item.text}`;
  return (parentText + childText).toLocaleLowerCase().replaceAll(' ', '-');
};

export const findActiveItems = (
  menuItem: MenuItemType,
  link: string,
): string[] => {
  if (
    menuItem.linkTo === link ||
    isSdbInnerModule(menuItem, link) ||
    menuItem.isVisible
  ) {
    return [menuItem.text];
  }
  let result: string[] = [];
  if (menuItem.children) {
    menuItem.children.forEach((i: MenuItemType) => {
      result = result.concat(findActiveItems(i, link));
    });
  }

  if (result.length > 0) {
    result.push(menuItem.text);
  }
  return result;
};

const clearedLink = (link?: string) => link?.replace(/[|]([^\n]+)/, '') || ''; // clear link for example 'some_link|negative='

export const testLink = (openedItemLink: string, itemLink: string) => {
  const regexp = new RegExp(`(^|/)${clearedLink(itemLink)}($|/)`);
  return regexp.test(clearedLink(openedItemLink));
};
export const getHasActiveChildren = (
  openedItemLink: string,
  children: MenuItemType[],
): boolean =>
  children.some((item) => {
    if (item.linkTo && testLink(openedItemLink, item.linkTo)) {
      return true;
    }

    if (item.children) {
      return getHasActiveChildren(openedItemLink, item.children);
    }

    return false;
  });

export const getMatchLink = (link?: string) => {
  return clearedLink(link);
};

export const getBadgeCount = (menuItem: MenuItemType, menuBadges: TBadges) => {
  let count = 0;
  if (menuItem.children) {
    menuItem.children.forEach((elem) => {
      count += getBadgeCount(elem, menuBadges);
    });
  } else {
    const matchLink = getMatchLink(menuItem.linkTo);

    if (matchLink) {
      const searchLink = last(matchLink.split('/'));
      count +=
        menuBadges?.[matchLink] || menuBadges?.[searchLink as string] || 0;
    }
  }
  return count;
};
