import { FC } from 'react';
import { Button } from 'react-ui-kit-exante';

import EnvelopeEmptyIcon from '../../../assets/i/EnvelopeEmpty.svg';
import EnvelopeFilledIcon from '../../../assets/i/EnvelopeFilled.svg';
import EnvelopeSentIcon from '../../../assets/i/EnvelopeSent.svg';
import { DigitInput } from '../DigitInput/DigitInput';
import { useDigitInput } from '../DigitInput/useDigitInput';
import { FormState, EMAIL_DIGIT_INPUT_LENGTH } from '../const';
import { ModalContentProps } from '../types';

import {
  CardContent,
  CardRoot,
  CardHeader,
  CardActions,
  CardInfo,
  CardImage,
  CardInfoAccent,
} from './Card';
import { ResendButton } from './ResendButton';

export const TwoFASettingsEmail: FC<
  React.PropsWithChildren<ModalContentProps>
> = ({
  handleClose,
  userName,
  title = '2-step authentication via email',
  buttonText,
  isTimerRunning,
  time,
  sendCode,
}) => {
  const { handleInputChange, inputValue, isFilled } = useDigitInput(
    EMAIL_DIGIT_INPUT_LENGTH,
  );

  const IconSrc = isFilled ? EnvelopeFilledIcon : EnvelopeEmptyIcon;

  return (
    <CardRoot>
      <CardHeader title={title} onClick={handleClose} type={FormState.EMAIL} />
      <CardContent>
        <CardImage
          alt="image"
          src={isTimerRunning && !isFilled ? EnvelopeSentIcon : IconSrc}
        />
        <CardInfo>
          Enter the authentication code below we sent to
          <CardInfoAccent
            text="We will use the email currently saved in your profile.
            If you want to use another email, please change it in your profile Settings"
          >
            {userName}
          </CardInfoAccent>
        </CardInfo>
      </CardContent>
      <CardActions>
        <ResendButton
          onClick={sendCode}
          isTimerRunning={isTimerRunning}
          time={time}
          type={FormState.EMAIL}
        />
        <DigitInput
          dataTestId={`profile-module__input--${FormState.EMAIL}`}
          onChange={handleInputChange}
          value={inputValue}
          length={EMAIL_DIGIT_INPUT_LENGTH}
        />
        <Button
          data-test-id={`profile-module__button--${FormState.EMAIL}-verify`}
          fullWidth
          autoFocus
          type="submit"
          disabled={!isFilled}
        >
          {buttonText}
        </Button>
      </CardActions>
    </CardRoot>
  );
};
