import { MenuConfig, TCrmBookmarksData } from '../../config/types';
import { RootState } from '../../store';

export const CrmBookmarksSelector = (state: RootState): TCrmBookmarksData =>
  state?.menu?.crm?.bookmarks;

export const CprmBookmarksSelector = (state: RootState): TCrmBookmarksData =>
  state?.menu?.cprm?.bookmarks;

export const MenuConfigSelector = (state: RootState): MenuConfig =>
  state?.menu?.config;

export const StateSelector = (state: RootState): RootState => state;
