import { ISortBy, TCommonParams } from 'react-ui-kit-exante';

import { IToken } from '../../entities/user/types';

const environments = {
  prod: 'prod',
  stage: 'stage',
  dev: 'dev',
};

export const isDev = () => process?.env?.REACT_APP_ENV === environments.dev;
export const isStage = () => process?.env?.REACT_APP_ENV === environments.stage;

export const isFeatureBuild = () =>
  process?.env?.REACT_APP_IS_FEATURE_BUILD === 'true';

export const prepareParams = ({
  sorting,
  skip,
  page,
  ...rest
}: TCommonParams) => ({
  ...(sorting as ISortBy),
  offset: page,
  ...rest,
});

export const getUrlWithHttps = (url?: string) => (url ? `https://${url}` : '');

export const getMainToken = (tokens: Array<IToken>) =>
  tokens?.find((token) => token.name === 'Main password');
