import { FC } from 'react';

import { IProfileEmailView } from '../../types';
import { FormGroup } from '../FormGroup/FormGroup';
import { ProfileInputField } from '../ProfileInputField/ProfileInputField';

export const ProfileEmailView: FC<
  React.PropsWithChildren<IProfileEmailView>
> = ({ userName }) => (
  <FormGroup>
    Email
    <ProfileInputField value={userName} name="email" isEditable={false} />
  </FormGroup>
);
