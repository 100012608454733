import { cloneDeep } from 'lodash';

import { ServiceMenuMap } from '../components/auth/const';
import { TService } from '../components/auth/types';
import { IFavourite } from '../components/menu/types';

import { MenuConfig, MenuItem, TCrmMenu, TCrmMenuItem } from './types';

/* eslint-disable no-param-reassign */
const deepPasteCrmMenu = (menu: MenuConfig, crmMenu: TCrmMenuItem[]) => {
  menu?.forEach((menuItem: MenuItem) => {
    const menuId = menuItem?.crmId;
    if (menuId) {
      const crmMenuItem = crmMenu.find(({ id }) => id === menuId);
      if (crmMenuItem) {
        if (!menuItem.children) {
          menuItem.linkTo = crmMenuItem.linkTo;
        }
        menuItem.text = crmMenuItem.text;
        menuItem.isSubmenuHeader = true;
      }
    }

    if (menuItem.children?.length) {
      deepPasteCrmMenu(menuItem.children, crmMenu);
    }
  });
  return menu;
};
/* eslint-enable no-param-reassign */

export const getMenuWithCrm = (
  menu: MenuConfig,
  crmMenu?: TCrmMenuItem[] | null,
) => {
  if (!crmMenu) {
    return menu;
  }
  const menuWithCrm = cloneDeep(menu);
  return deepPasteCrmMenu(menuWithCrm, crmMenu);
};

export const getMenuWithFavourites = (
  menu: MenuConfig,
  favourites: IFavourite[],
) => {
  const menuWithCrm = cloneDeep(menu);
  // TODO: to consider whether this item can be loaded from the branding
  const favouritesItem: MenuItem = {
    vpn: false,
    text: 'Favourites',
    iconSrc: {
      active: '/assets/i/Favourites.svg',
      default: '/assets/i/Favourites.svg',
    },
    defaultLink: '/favourites',
    isVisible: true,
    isFavourite: true,
  };

  if (favourites?.length !== 0) {
    favouritesItem.children = favourites.map((item) => ({
      ...item,
      isSubmenuHeader: true,
    }));
  }

  return [favouritesItem, ...menuWithCrm];
};

const mapServiceMenuName = (moduleName: TService) =>
  (ServiceMenuMap[moduleName] as TService) ?? moduleName;

export const disableNotAllowedServices = (
  menu: MenuConfig,
  allowedServices: TService[],
) => {
  if (!allowedServices?.length) {
    return menu;
  }

  const filteredMenu: MenuConfig = menu.reduce<MenuConfig>((acc, item) => {
    if (!item.children) {
      if (!item.linkTo && !item.defaultLink) {
        return acc;
      }
      const splittedLink = item.linkTo?.slice(1)?.split('/', 1);

      if (splittedLink) {
        const [moduleName] = splittedLink as TService[];
        const mappedModuleName = mapServiceMenuName(moduleName);

        if (!allowedServices.includes(mappedModuleName)) {
          return acc;
        }
      }
    }

    if (item.children?.length) {
      const filteredChildren = disableNotAllowedServices(
        item.children,
        allowedServices,
      );

      if (filteredChildren.length === 0) {
        return acc;
      }

      return [...acc, { ...item, children: filteredChildren }];
    }

    return [...acc, item];
  }, []);

  return filteredMenu;
};

export const mapMenu = (key: 'crm' | 'cprm', menu: TCrmMenu) => {
  return menu?.data.map((i) => ({
    ...i,
    linkTo: i.isExternal
      ? `/${key}-${i.linkTo.substring(1)}`
      : `/${key}${i.linkTo}`,
    id: `${key}-${i.id}`,
    ...(i.bookmarkTab && { bookmarkTab: `${key}-tab-${i.bookmarkTab}` }),
  }));
};

export const getBookmarksDistributedByTabs = (
  bookmarksData: TCrmMenuItem[],
  serviceName?: string,
) => {
  const tabs = bookmarksData.filter((el: TCrmMenuItem) => !el.bookmarkTab);
  const bookmarks = tabs.map((tab: TCrmMenuItem) => ({
    ...tab,
    linkTo: `/${serviceName}${tab.linkTo}`,
    children: bookmarksData
      .filter(
        (el: TCrmMenuItem) =>
          el.bookmarkTab && el.bookmarkTab === tab.id.replace('tab-', ''),
      )
      .map((el) => ({
        ...el,
        linkTo: `/${serviceName}${
          serviceName === 'bo' ? `/${el.bookmarkTab}` : ''
        }${el.linkTo}`,
      })),
  }));
  return bookmarks;
};
