import { useCallback, MouseEvent } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { useHomePath } from '../../../hooks/useHomePath';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useServiceContext } from '../../services/ServiceProvider';
import { AuthForm } from '../AuthForm/AuthForm';
import { AuthSelector } from '../selectors';
import { setInitialState } from '../slices/authSlice';
import { FormState, TLocationState } from '../types';

import { AuthLogin2FA } from './AuthLogin2FA';
import { AuthLoginMain } from './AuthLoginMain';

export const AuthLogin = () => {
  const { ctx } = useServiceContext();
  const homePath = useHomePath();

  const state = useAppSelector(AuthSelector);
  const { isAuthorized, errorAuth, error2fA, formState } = state;

  const dispatch = useAppDispatch();

  const history = useHistory();
  const location = useLocation();

  const { from } = (location.state as TLocationState) || { from: null };

  const handleReturn = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();

      dispatch(setInitialState());
    },
    [dispatch],
  );

  let renderForm = (
    <AuthLoginMain
      error={errorAuth}
      title="Created by professionals. <br/>For professionals."
      {...ctx}
    />
  );

  if (formState !== FormState.AUTH) {
    renderForm = (
      <AuthLogin2FA
        error={error2fA}
        title="2-Step Authentication"
        onReturn={handleReturn}
        {...ctx}
      />
    );
  }

  if (isAuthorized) {
    if (!from) {
      return <Redirect to={homePath} />;
    }

    history.replace(from);
  }

  return <AuthForm>{renderForm}</AuthForm>;
};
