import * as base32 from 'hi-base32';

import { IToken, TTokenType } from '../../entities/user/types';
import { getMainToken } from '../../shared/helpers/apiHelpers';
import { AuthDBService } from '../services/AuthDB.service';

import { CHARACTERS, FormState, UniqIdLength } from './const';

const findExistingKey = (tokens: Array<IToken>, type: TTokenType) =>
  tokens.find((token) => token.type === type);

export const getValidationUrl = async (
  authDBService: AuthDBService,
  type: TTokenType,
  userId: number,
  tokenId: number,
) => {
  try {
    if (type === FormState.GA) {
      return await authDBService.getTotpValidationUrl(userId, tokenId);
    }

    return await authDBService.getValidationId(userId, tokenId);
  } catch (err: any) {
    if (err.request) {
      const { responseURL } = err.request;
      return responseURL;
    }

    throw new Error(err);
  }
};

export const sendConfirmationCode = async (
  authDBService: AuthDBService,
  userId: number,
  type: TTokenType = 'totp',
  value = '',
) => {
  try {
    const { tokens } = await authDBService.getUserInfo(userId);
    const mainToken = getMainToken(tokens);

    const response = await authDBService.createToken(type, userId, value);

    let { id: tokenid } = response;

    if (!tokenid) {
      const token = findExistingKey(tokens, type);
      tokenid = token?.id;
    }

    const url = await getValidationUrl(
      authDBService,
      type,
      userId,
      tokenid as number,
    );

    return {
      tokenid,
      url,
      mainTokenId: mainToken?.id,
    };
  } catch (err: any) {
    throw new Error(err);
  }
};

export const checkTotpToken = async (
  userId: number,
  authDBService: AuthDBService,
  value = '',
  type: TTokenType = 'totp',
) => {
  try {
    const response = await authDBService.createToken(type, userId, value);

    const { tokens } = await authDBService.getUserInfo(userId);
    const mainToken = getMainToken(tokens);

    let { id: tokenid } = response;

    if (!tokenid) {
      const token = findExistingKey(tokens, type);
      tokenid = token?.id;

      await authDBService.updateToken(userId, tokenid as number, value);
    }

    const url = await getValidationUrl(authDBService, type, userId, tokenid);

    return {
      url,
      tokenId: tokenid,
      mainTokenId: mainToken?.id,
    };
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getToken = async (
  authDBService: AuthDBService,
  userId: number,
  type: TTokenType = 'totp',
) => {
  try {
    const { tokens } = await authDBService.getUserInfo(userId);

    const mainToken = getMainToken(tokens);

    const token = findExistingKey(tokens, type);
    const tokenId = token?.id;

    const url = await getValidationUrl(
      authDBService,
      type,
      userId,
      tokenId as number,
    );

    return {
      url,
      tokenId,
      mainTokenId: mainToken?.id,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const makeid = (length: number) => {
  let result = '';
  const charactersLength = CHARACTERS.length;

  for (let i = 0; i < length; i += 1) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getQrString = (userName: string) => {
  const uniqId = makeid(UniqIdLength);
  const keyb32 = base32.encode(decodeURI(encodeURI(uniqId)));

  return {
    uri: `otpauth://totp/${userName}(EXANTE)?secret=${keyb32}&EXANTE`,
    uniqId,
  };
};
