import { styled } from '@mui/material/styles';
import { FC, useCallback, useState, ComponentType } from 'react';
import { blockNonNativeProps } from 'react-ui-kit-exante';

import { CardRoot } from '../../Card';

import {
  IBodyProps,
  ICardCoverProps,
  ICardStateProps,
  IRootProps,
  ITitleProps,
} from './types';

const Title = styled('div')(({ isDisabled, isHovered, theme }: ITitleProps) => {
  const activeCls = {
    color: isHovered ? theme?.color.typo.primary : theme?.color.typo.action,
  };

  const disabledCls = {
    color: theme?.color.typo.promo,
  };

  const cls = isDisabled ? disabledCls : activeCls;

  return {
    marginBottom: '8px',
    lineHeight: '32px',
    fontSize: '20px',
    fontFamily: theme?.font?.main,
    fontWeight: 500,
    ...cls,
  };
}) as ComponentType<React.PropsWithChildren<ITitleProps>>;

const Body = styled('div')(({ isDisabled, theme }: IBodyProps) => ({
  fontWeight: 'normal',
  fontSize: '15px',
  fontFamily: theme?.font?.main,
  lineHeight: '24px',
  margin: '0 -1px',
  textAlign: 'center',
  color: isDisabled ? theme?.color.typo.promo : theme?.color.typo.secondary,
})) as ComponentType<React.PropsWithChildren<IBodyProps>>;

const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'calc(50%-40px)',
}));

const Root = styled(CardRoot, {
  shouldForwardProp: blockNonNativeProps(['isDisabled']),
})(({ isDisabled, theme }: IRootProps) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  backgroundColor: theme?.color.bg.primary,
  transition: 'background-color .2s',
  '&:hover': !isDisabled
    ? {
        border: `2px solid ${theme?.color.typo.action}`,
        backgroundColor: theme?.color.datepicker.daySelected,
        cursor: 'pointer',
      }
    : undefined,
})) as ComponentType<React.PropsWithChildren<IRootProps>>;

const CardState = styled('div')(({ theme, isHovered }: ICardStateProps) => ({
  position: 'absolute',
  bottom: '40px',
  fontStyle: theme?.font?.main,
  fontSize: '15px',
  lineHeight: '24px',
  fontWeight: 600,
  color: isHovered ? theme?.color.typo.primary : theme?.color.typo.action,
})) as ComponentType<React.PropsWithChildren<ICardStateProps>>;

export const CardCover: FC<React.PropsWithChildren<ICardCoverProps>> = ({
  dataTestId,
  icon,
  title,
  text,
  value,
  onClick,
  token,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isDisabled = token && token?.type !== value;
  const isActivated = token && token?.type === value;

  const { activated, disabled, enabled, hovered } = icon;
  const enabledIcon = isActivated ? activated : enabled;
  const iconElement = isHovered ? hovered : enabledIcon;

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseOver = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleClick = () => {
    onClick(value);
  };

  return (
    <Root
      data-test-id={dataTestId}
      onClick={isDisabled ? () => {} : handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOver}
      isDisabled={isDisabled}
    >
      <Content>
        {isDisabled ? disabled : iconElement}
        <Title isDisabled={isDisabled} isHovered={isHovered}>
          {title}
        </Title>
        <Body isDisabled={isDisabled}>{text}</Body>
      </Content>
      {isActivated && (
        <CardState isHovered={isHovered}>
          {isHovered ? 'Disable' : 'Activated'}
        </CardState>
      )}
    </Root>
  );
};
