export function getRoute(location: { hash: string }) {
  const [moduleName, id] = location.hash.split('#/').join('').split('/');
  if (id) {
    let moduleNameOne = moduleName.substr(0, moduleName.length - 1);
    if (moduleNameOne === 'currencie') {
      moduleNameOne = 'currency';
    }
    return { url: `${moduleName}.${moduleNameOne}`, id };
  }
  return { url: moduleName };
}
