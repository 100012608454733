import { FC } from 'react';
import { matchPath, Redirect, Route, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../store/hooks';
import { AuthSelector } from '../auth/selectors';
import {
  getAccessToken,
  getSessionByModuleName,
} from '../services/Cookies.service';

import { PrivateRouteProps, Routes } from './types';

export const PrivateRoute: FC<React.PropsWithChildren<PrivateRouteProps>> = (
  props,
) => {
  const { children, module, ...rest } = props;
  const { path } = props;
  const { isAuthorized } = useAppSelector(AuthSelector);
  const token = getAccessToken();

  const isModuleAuthPage =
    matchPath(path as string, {
      path: [Routes.MODULE_AUTH],
      exact: true,
    }) !== null;

  const currentLocation = useLocation();

  if (isAuthorized) {
    const sessionId = getSessionByModuleName(module);

    const hasAccessToken = Boolean(token?.access_token);

    const isUnauthorizedModule =
      Boolean(module) && !sessionId && !hasAccessToken;

    return (
      <Route
        {...rest}
        render={({ location }) =>
          isUnauthorizedModule ? (
            <Redirect
              to={{
                pathname: `${location.pathname}/auth`,
                search: `?to=${module}`,
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  return (
    <Redirect
      to={{
        pathname: Routes.AUTH,
        state: { from: !isModuleAuthPage ? currentLocation : null },
      }}
    />
  );
};
