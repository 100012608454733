import { FC } from 'react';

import { SettingsFormValues } from '../../const';
import { IProfilePhoneEdit } from '../../types';
import { FormGroup } from '../FormGroup/FormGroup';
import { ProfileInputField } from '../ProfileInputField/ProfileInputField';

export const ProfilePhoneEdit: FC<IProfilePhoneEdit> = ({
  handleSubmit,
  phone,
  editField,
  setEditField,
}) => (
  <FormGroup>
    Phone
    <ProfileInputField
      editField={editField}
      setEditField={setEditField}
      name={SettingsFormValues.PHONE}
      onSave={handleSubmit}
      value={phone}
    />
  </FormGroup>
);
