import { useApi } from '../../config/useApi';
import { USER_PREFIX_URL } from '../../shared/const';
import { APIV2, APIV21 } from '../../shared/constants/Various.constant';
import { AUTH_SERVICE, StatusCodes } from '../auth/const';

import {
  updateInstanceToken,
  apiService,
  createInstance,
} from './Api/Api.service';
import {
  getAccessToken,
  getAuthorisedServiceName,
  getSessionByModuleName,
} from './Cookies.service';

const authInstance = createInstance(AUTH_SERVICE);

export const useAuthDBService = () => {
  const { getAuthDBBaseUrl } = useApi();
  const token = getAccessToken();
  const serviceName = getAuthorisedServiceName();
  // for personal settings API any authorized session will be valid
  const anyAuthorizedSessionToken = getSessionByModuleName(serviceName);
  const boToken = getSessionByModuleName(AUTH_SERVICE);
  const sessionToken = boToken || anyAuthorizedSessionToken;

  const authDBApi = apiService(getAuthDBBaseUrl(), authInstance);

  updateInstanceToken(authInstance, sessionToken, token);

  const getUserInfo = async (userid: number) => {
    try {
      const { data } = await authDBApi.get({
        url: getAuthDBBaseUrl(APIV2),
        endPoint: `${USER_PREFIX_URL}${userid}`,
      });
      return data;
    } catch (err: any) {
      if (err.response) {
        const { data } = err.response;
        throw new Error(data.error);
      }
      throw new Error(err);
    }
  };

  const updateUser = async (username: string, info: any) =>
    authDBApi.put({
      endPoint: `${USER_PREFIX_URL}${username}`,
      data: { ...info },
    });

  const createToken = async (type: string, userId: number, value = '') => {
    try {
      const { data } = await authDBApi.post({
        url: getAuthDBBaseUrl(APIV21),
        endPoint: `${USER_PREFIX_URL}${userId}/tokens`,
        data: { name: `2fa/${type}`, type, value },
      });
      return data;
    } catch (err: any) {
      if (err.response) {
        const { data, status } = err.response;

        if (status === StatusCodes.FORBIDDEN) {
          const { error, permission } = data;
          throw new Error(`${error} to "${permission}"`);
        }

        return data;
      }

      throw new Error(err);
    }
  };

  const validateTokenCode = async (url: string, value: string) => {
    try {
      return await authDBApi.post({
        url,
        data: {
          value,
        },
        sessionToken,
      });
    } catch (err: any) {
      if (err.response) {
        throw new Error(JSON.stringify(err.response));
      }
      throw new Error(err);
    }
  };

  const getTotpValidationUrl = (userId: number, tokenid: number) =>
    authDBApi.post({
      url: getAuthDBBaseUrl(APIV21),
      endPoint: `${USER_PREFIX_URL}${userId}/tokens/${tokenid}/check/`,
    });

  const getValidationId = (userId: number, tokenid: number) =>
    authDBApi.post({
      url: getAuthDBBaseUrl(APIV21),
      endPoint: `${USER_PREFIX_URL}${userId}/tokens/${tokenid}/check/`,
      data: {
        value: '',
      },
    });

  const updateServiceToken = async (
    username: string,
    service: string,
    maintokenid: number,
    tokenid: number | null = null,
  ) => {
    try {
      const { data } = await authDBApi.post({
        endPoint: `${USER_PREFIX_URL}${username}/service/${service}`,
        data: {
          maintokenid: `${maintokenid}`,
          tokens: [
            {
              tokenid,
            },
          ],
        },
      });
      return data;
    } catch (err: any) {
      if (err.response) {
        const { data, status, statusText } = err.response;

        if (status === StatusCodes.FORBIDDEN) {
          const { error, permission } = data;
          throw new Error(`${error} to "${permission}"`);
        }

        throw new Error(statusText);
      }

      throw new Error(err);
    }
  };

  const updateToken = async (userId: number, tokenid: number, value: string) =>
    authDBApi.put({
      url: getAuthDBBaseUrl(APIV21),
      endPoint: `${USER_PREFIX_URL}${userId}/tokens/${tokenid}`,
      data: {
        value,
      },
    });

  const checkAccessToken = async (access_token: string) => {
    try {
      const { data } = await authDBApi.get({
        endPoint: `/auth/token_info?access_token=${access_token}`,
      });
      return data;
    } catch (err: any) {
      if (err.response) {
        const { data } = err.response;
        throw new Error(data.error);
      }
      throw new Error(err);
    }
  };

  const bindExternalUser = async (code: string) => {
    try {
      return await authDBApi.post({
        endPoint: '/auth/connect',
        data: {
          connect_token: code,
        },
      });
    } catch (err: any) {
      if (err.response) {
        throw new Error(JSON.stringify(err.response));
      }
      throw new Error(err);
    }
  };

  const deleteUserExternalBinding = async (
    userId: number,
    bindingId: number,
  ) => {
    try {
      return await authDBApi.delete({
        url: getAuthDBBaseUrl(APIV21),
        endPoint: `${USER_PREFIX_URL}${userId}/external_bindings/${bindingId}`,
      });
    } catch (err: any) {
      if (err.response) {
        throw new Error(JSON.stringify(err.response));
      }
      throw new Error(err);
    }
  };

  const getUserExternalBinding = async (userId: number) => {
    try {
      const { data } = await authDBApi.get({
        url: getAuthDBBaseUrl(APIV21),
        endPoint: `/auth/user/${userId}/external_bindings/`,
      });

      return data;
    } catch (err: any) {
      if (err.response) {
        throw new Error(JSON.stringify(err.response));
      }
      throw new Error(err);
    }
  };

  const getUserAuthFlows = async (userId: number) => {
    try {
      const { data } = await authDBApi.get({
        url: getAuthDBBaseUrl(APIV21),
        endPoint: `${USER_PREFIX_URL}${userId}/authflow`,
      });
      return Array.isArray(data) ? data : [data];
    } catch (err: any) {
      if (err.response) {
        throw new Error(JSON.stringify(err.response));
      }
      throw new Error(err);
    }
  };

  const updateAuthFlows = async (
    userId: number,
    mainTokenId: number,
    tokenId?: number,
  ) => {
    try {
      return await authDBApi.post({
        url: getAuthDBBaseUrl(APIV21),
        endPoint: `${USER_PREFIX_URL}${userId}/authflow`,
        data: {
          firstStep: mainTokenId,
          secondSteps: tokenId ? [tokenId] : [],
        },
      });
    } catch (err: any) {
      if (err.response) {
        throw new Error(JSON.stringify(err.response));
      }
      throw new Error(err);
    }
  };

  return {
    getUserInfo,
    updateUser,
    createToken,
    validateTokenCode,
    getTotpValidationUrl,
    getValidationId,
    updateServiceToken,
    updateToken,
    checkAccessToken,
    bindExternalUser,
    deleteUserExternalBinding,
    getUserExternalBinding,
    getUserAuthFlows,
    updateAuthFlows,
  };
};

export type AuthDBService = ReturnType<typeof useAuthDBService>;
