import { styled } from '@mui/material/styles';

import { StyledProps } from '../../../../../../../shared/helpers/types';

export const LoginControlsTitle = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: 'Inter, sans-serif',
  textAlign: 'center',
  width: '100%',
  color: theme?.color.typo.secondary,
  position: 'relative',
  '&:before': {
    content: "''",
    left: 0,
    right: 0,
    backgroundColor: theme?.color?.dropdown?.border,
    height: '1px',
    position: 'absolute',
    top: '50%',
  },
}));

export const LoginControlsTitleContent = styled('span')(
  ({ theme }: StyledProps) => ({
    position: 'relative',
    backgroundColor: theme?.color?.bg?.primary,
    fontSize: '12px',
    lineHeight: '16px',
    padding: '8px',
  }),
);

export const LoginWarning = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.main,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: theme?.color?.notifications?.warning,
  marginBottom: '16px',
}));
