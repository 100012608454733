import { useMemo } from 'react';
import { useAsync } from 'react-async-hook';

import { useNodeBackService } from '../../../services/NodeBack.service';
import { Currency } from '../../../services/Types/currencies.types';
import { prepareCurrenciesList } from '../CurrencySelector.helpers';

let cachedCurrencies: Currency[] | null = null;

export const useFetchCurrencies = () => {
  const { getCurrencies } = useNodeBackService();
  const {
    result: currencies = [],
    loading,
    error,
  } = useAsync(async () => {
    if (!cachedCurrencies) {
      cachedCurrencies = await getCurrencies();
    }
    return cachedCurrencies;
  }, []);
  const preparedCurrencies = useMemo(
    () => prepareCurrenciesList(currencies),
    [currencies],
  );

  return {
    currencies: preparedCurrencies,
    loading,
    error,
  };
};
