import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useAuthContext } from '../auth/AuthProvider';
import { LOCAL_STORAGE_KEY_LAST_PAGE } from '../auth/const';

interface IErrorFallbackProps {
  error: Error;
  resetErrorBoundary(): void;
}

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: IErrorFallbackProps) => {
  const { removeItem } = useLocalStorage();
  const { logout } = useAuthContext();
  console.error(error);

  const handleClick = () => {
    removeItem(LOCAL_STORAGE_KEY_LAST_PAGE);
    logout();
    resetErrorBoundary();
  };

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={handleClick}>Try again</button>
    </div>
  );
};
