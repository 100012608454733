import * as cookies from 'js-cookie';

import {
  ACCESS_TOKEN_KEY,
  COOKIE_KEY_PREFIX,
  USER_KEY,
} from '../../shared/constants/Cookies.constant';
import { SERVICES } from '../auth/const';
import { TToken, TService } from '../auth/types';

const formKey = (key: TService) => `${COOKIE_KEY_PREFIX}_${key}_session_id`;

export const getAuthorisedServiceName = () =>
  SERVICES.find((key) => cookies.get(formKey(key)));

export const getSessionByModuleName = (moduleName?: TService) =>
  moduleName ? cookies.get(formKey(moduleName)) : undefined;

export function saveUsername(username: string) {
  cookies.set(USER_KEY, username);
}

export function getUsername() {
  return cookies.get(USER_KEY);
}

export function clearUsername() {
  return cookies.remove(USER_KEY);
}

export function saveAccessToken(access_token: TToken) {
  cookies.set(ACCESS_TOKEN_KEY, JSON.stringify(access_token), {
    expires: new Date(access_token.exp * 1000),
  });
}

export const getAccessToken = () => {
  const token = cookies.get(ACCESS_TOKEN_KEY) as string;
  return token && JSON.parse(token);
};

export const clearAccessToken = () => {
  cookies.remove(ACCESS_TOKEN_KEY);
};
