import { styled } from '@mui/material/styles';

export const StyledAuthLayout = styled('div')`
  --margin: 16px;
  position: relative;
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: var(--margin);

  min-height: calc(100vh - var(--margin) * 2);

  background-color: ${({ theme }) => theme?.color.bg.primary};
`;

export const StyledAuthWrapper = styled('div')`
  padding: 24px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 40px 24px;

    width: 408px;
    margin: 0 auto;
  }
`;

export const StyledAuthService = styled('div')`
  padding: 8px;
  background-color: ${({ theme }) => theme.color.bg.promo};
  font-family: ${({ theme }) => theme.font.main};
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  position: absolute;
  color: ${({ theme }) => theme.color.typo.inverse};
  top: 0;
  left: 24px;
`;

export const StyledAuthContent = styled('div')`
  width: 100%;
`;

export const StyledAuthLogo = styled('div')`
  margin-bottom: 24px;
  min-height: 40px;

  text-align: center;

  .AuthLogoSvg {
    width: 144px;
    height: 32px;
  }
`;
