import { Link, styled } from 'react-ui-kit-exante';

export const StyledAuthForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAuthLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;

  font-family: ${({ theme }) => theme.font.main};
  font-size: 12px;
  line-height: 16px;
`;
