import { FC } from 'react';

import { AuthHeaderProps } from '../types';

import { StyledAuthHeader } from './AuthHeader.styled';

export const AuthHeader: FC<React.PropsWithChildren<AuthHeaderProps>> = ({
  title,
}) => (
  <StyledAuthHeader
    className="AuthHeader"
    dangerouslySetInnerHTML={{
      __html: title || '',
    }}
  />
);
