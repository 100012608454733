import { createTheme } from '@mui/material/styles';

import { useLocalStorage } from '../hooks/useLocalStorage';

import { LIGHT_THEME, LOCAL_STORAGE_KEY_COLOR_SCHEME } from './const';
import { TColorScheme } from './types';

const localDefaultTheme = {};

const localDarkTheme = {};

const ComputedTheme = () => {
  const { getItem } = useLocalStorage();

  const mode =
    (getItem(LOCAL_STORAGE_KEY_COLOR_SCHEME) as TColorScheme) || LIGHT_THEME;

  const localTheme = mode === LIGHT_THEME ? localDefaultTheme : localDarkTheme;

  return localTheme;
};

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    color: Record<string, any>;
    effect: Record<string, any>;
    font: Record<string, any>;
    filters: Record<string, any>;
  }
}

export const theme = createTheme(ComputedTheme() as any);
