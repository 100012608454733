export enum SettingsFormValues {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  FULL_NAME = 'fullName',
  NAME_SURNAME = 'nameSurname',
  PASSWORD = 'password',
  PHONE = 'phone',
  OLD_PASSWORD = 'oldPassword',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_NEW_PASSWORD = 'confirmNewPassword',
  CODE = 'code',
}

export enum FormState {
  PASSWORD = 'password',
  SMS = 'sms',
  GA = 'totp',
}

export const TimerValue = 60;
export const EndTimeValue = 0;
