import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
  padding: '8px',
  border: '1px solid #EBEBEB',
  borderRadius: '4px',
  maxWidth: '312px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:not(:first-child)': {
    marginTop: '4px',
  },
}));

export const ProviderName = styled('div')(() => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '16px',
  textTransform: 'capitalize',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const SwitchWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));
