import { useContext, useEffect, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useAuthDBService } from '../../../../../components/services/AuthDB.service';
import { ConfigContext } from '../../../../../config/ConfigContext';
import { useAppSelector } from '../../../../../store/hooks';
import { UserInfoSelector } from '../../../selectors';
import { IAccountData } from '../../types';
import { AccountSwitch } from '../AccountSwitch/AccountSwitch';

import { Header } from './ExternalAccounts.styled';

const initialData: IAccountData[] = [
  { providerName: 'google' },
  { providerName: 'appleid' },
];

const mergeAccounts = (data: IAccountData[], newData: IAccountData[]) => {
  // Creating a new Map to store unique providerNames and their objects
  const mergedMap = new Map();

  // Adding objects from initialData to the Map
  data.forEach((item) => {
    mergedMap.set(item.providerName, item);
  });

  // Updating the Map with objects from newData if providerName matches
  newData.forEach((item) => {
    if (mergedMap.has(item.providerName)) {
      mergedMap.set(item.providerName, item);
    }
  });

  // Converting the Map values back to an array
  return Array.from(mergedMap.values());
};

export const ExternalAccounts = () => {
  const authDBService = useAuthDBService();
  const { id: userId } = useAppSelector(UserInfoSelector);
  const { auth_flows: authFlows } = useContext(ConfigContext);
  const [accounts, setAccounts] = useState<IAccountData[]>(initialData);
  const enabledAccounts = authFlows
    .filter((flow) => flow.type === 'oauth')
    .map((flow) => flow.type === 'oauth' && flow.isOn && flow.hint);

  useEffect(() => {
    if (userId) {
      (async () => {
        try {
          const data: IAccountData[] =
            await authDBService.getUserExternalBinding(userId);
          setAccounts((prevState) => mergeAccounts(prevState, data));
        } catch (err: any) {
          const response = JSON.parse(err?.message);
          if (response.status === 404) {
            return;
          }
          Notification.error({
            title: response?.data?.message,
          });
        }
      })();
    }
  }, [userId]);
  return (
    <>
      <Header>External accounts</Header>
      {accounts
        .filter((account) => enabledAccounts.includes(account.providerName))
        .map((account) => (
          <AccountSwitch
            key={account.providerName}
            providerName={account.providerName}
            data={account}
            userId={userId}
          />
        ))}
    </>
  );
};
