import { useCallback, useEffect, useState } from 'react';

import { useMenuConfig } from '../../config/useMenuConfig';

import { WAIT_RESPONSE_TIMEOUT } from './const';
import { isVPNModule } from './helpers';
import { TModules } from './types';

export const useAlert = (isScriptsLoaded: boolean, module: TModules) => {
  const [showAlert, setAlert] = useState(false);
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();

  const setAlertCallback = useCallback(() => {
    if (!isScriptsLoaded && isVPNModule(module, menuConfig)) {
      setAlert(true);
    }
  }, [isScriptsLoaded]);

  useEffect(() => {
    const timer = setTimeout(setAlertCallback, WAIT_RESPONSE_TIMEOUT);

    return () => clearTimeout(timer);
  }, [setAlertCallback]);

  return {
    showAlert,
  };
};
