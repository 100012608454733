import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'react-ui-kit-exante';

export const InputField: FC<React.PropsWithChildren<any>> = ({
  control,
  errors,
  name,
  value,
}) => {
  const hasError = Boolean(errors[name]?.message);

  return (
    <Controller
      defaultValue={value}
      control={control}
      name={name}
      render={({ field }) => (
        <Input
          inputProps={{
            'data-test-id': `settings-module__input--${name}`,
          }}
          error={hasError}
          size="small"
          sx={{
            mr: '8px',
          }}
          {...field}
        />
      )}
    />
  );
};
