import { useContext } from 'react';

import {
  API,
  APIV1,
  CAS_APIV1,
  SDB_APIV1,
} from '../shared/constants/Various.constant';

// eslint-disable-next-line import/no-cycle
import { ConfigContext } from './ConfigContext';
import { Service } from './types';

const useApi = () => {
  const state = useContext(ConfigContext);

  const getApiUrl = (serviceName: Service, withProtocol = true) => {
    if (state.apis && serviceName in state.apis) {
      return withProtocol
        ? `https://${state.apis[serviceName]}`
        : state.apis[serviceName];
    }
    // eslint-disable-next-line no-console
    console.error(
      `Service ${serviceName} doesn't exists or services list is empty`,
    );
    return '';
  };

  const getCorporateActionsBaseUrl = (apiVersion = CAS_APIV1) =>
    `${getApiUrl(Service.CAS)}${apiVersion}`;

  const getAuthDBBaseUrl = (apiVersion = APIV1) =>
    `${getApiUrl(Service.AuthDB)}${apiVersion}`;

  const getSymbolDBBaseUrl = (apiVersion = SDB_APIV1) =>
    `${getApiUrl(Service.SymbolDBEditor)}${apiVersion}`;

  const getCheckpointBaseUrl = (apiVersion = APIV1) =>
    `${getApiUrl(Service.Checkpoint)}${apiVersion}`;

  const getCrmApiBaseUrl = () => `${getApiUrl(Service.CrmApi)}`;

  const getCprmApiBaseUrl = () => `${getApiUrl(Service.CprmApi)}`;

  const getNodeBackUrl = () => `${getApiUrl(Service.NodeBack)}${API}`;

  const getBrandingBaseUrl = () => `${getApiUrl(Service.Branding)}`;

  const getShaperBaseUrl = () => `${getApiUrl(Service.NS_API)}`;

  return {
    getApiUrl,
    getAuthDBBaseUrl,
    getCheckpointBaseUrl,
    getSymbolDBBaseUrl,
    getCorporateActionsBaseUrl,
    getCrmApiBaseUrl,
    getCprmApiBaseUrl,
    getNodeBackUrl,
    getBrandingBaseUrl,
    getShaperBaseUrl,
  };
};

export { useApi, Service };
