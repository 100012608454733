import { Service } from '../auth/const';
import {
  getAccessToken,
  getSessionByModuleName,
} from '../services/Cookies.service';

export const getIframe = (el: Element, link: string) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', link);
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.verticalAlign = 'top';
  // eslint-disable-next-line no-param-reassign
  el.innerHTML = '';
  el.appendChild(iframe);
  return iframe;
};

export const appendIframe = (iframe: any, service: Service, apiUrl: string) => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `https://${apiUrl}/rest/oauth/set-cookie/`;

  const token = getAccessToken();
  const sessionToken = getSessionByModuleName(service);

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = sessionToken ? 'session_id' : 'access_token';
  input.value = sessionToken || token.access_token;
  form.appendChild(input);

  if (iframe.contentDocument) {
    iframe.contentDocument.body.appendChild(form);
  }

  form.submit();
};
