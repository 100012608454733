import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DetailsFormValues } from '../../components/profile/types';
import { AuthDBService } from '../../components/services/AuthDB.service';

import { IUserData, IUserState } from './types';

const initialState: IUserState = {
  authflows: [],
  tokens: [],
  auths: [],
  info: {
    archived: false,
    company: '',
    creationDate: null,
    hasAccessTo: '',
    id: null,
    lastLoginService: '',
    lastLoginTime: null,
    legalEntity: '',
    localization: '',
    middleName: null,
    firstName: '',
    lastName: '',
    primaryPhone: '',
    residence: '',
    userName: '',
    userType: '',
    username: '',
  },
};

export const fetchUserInfo = createAsyncThunk(
  'user/getInfo',
  async ({
    userId,
    authDBService,
  }: {
    userId: number;
    authDBService: AuthDBService;
  }) => {
    try {
      return await authDBService.getUserInfo(userId);
    } catch (error: any) {
      throw new Error(error);
    }
  },
);

export const fetchUserAuthflows = createAsyncThunk(
  'user/getAuthlows',
  async ({
    userId,
    authDBService,
  }: {
    userId: number;
    authDBService: AuthDBService;
  }) => {
    try {
      return await authDBService.getUserAuthFlows(userId);
    } catch (error: any) {
      throw new Error(error);
    }
  },
);

export const changeUserInfo = createAsyncThunk<
  IUserData,
  DetailsFormValues,
  { state: any }
>(
  'user/changeUserInfo',
  async ({ phone, firstName, lastName, authDBService }, { getState }) => {
    const {
      user: {
        info: { userName, userType },
      },
    } = getState();

    try {
      const { data } = await authDBService.updateUser(userName, {
        primaryPhone: phone,
        firstName,
        lastName,
        userType,
      });
      return data;
    } catch (err: any) {
      if (err.response) {
        const {
          data: { error },
        } = err.response;
        throw new Error(error);
      }
      throw new Error(err);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInitialState: (state) => ({
      ...state,
      ...initialState,
    }),
    updateAuthlows: (state, action) => ({
      ...state,
      authflows: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      const newState = {
        ...state,
        tokens: action.payload?.tokens,
        info: {
          ...action.payload?.info,
          userName: action.payload?.username,
        },
      };

      if (action.payload?.auths) {
        newState.auths = [...action.payload.auths];
      }

      return newState;
    });
    builder.addCase(fetchUserAuthflows.fulfilled, (state, action) => {
      return {
        ...state,
        authflows: action.payload,
      };
    });
    builder.addCase(changeUserInfo.fulfilled, (state, action) => ({
      ...state,
      info: {
        ...action.payload?.info,
        userName: action.payload?.username,
      },
      auths: [...action.payload.auths],
    }));
    builder.addCase(changeUserInfo.rejected, (state, action) => {
      console.error(action.error);
    });
  },
});

export const userReducer = userSlice.reducer;
export const { setInitialState, updateAuthlows } = userSlice.actions;
