import { FC, useCallback, useState } from 'react';
import { animated, useSpring } from 'react-spring';

export const CloseSvg: FC<React.PropsWithChildren<{ className: string }>> = ({
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const animationStyle = useSpring({
    transform: isHovered ? 'rotate(0deg)' : 'rotate(-90deg)',
  });

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseOver = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <animated.svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transformOrigin: 'center',
        transformBox: 'fill-box',
        ...animationStyle,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOver}
    >
      <path
        d="M12 4L4 12"
        stroke="#4E5D60"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4L12 12"
        stroke="#4E5D60"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </animated.svg>
  );
};
