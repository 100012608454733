import { FC, useCallback } from 'react';
import 'react-phone-number-input/style.css';
import { Notification } from 'react-ui-kit-exante';

import { UserInfoSelector } from '../../entities/user/selectors';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { useAuthContext } from '../auth/AuthProvider';
import { useAuthDBService } from '../services/AuthDB.service';

import { SettingsForm } from './SettingsForm/SettingsForm';
import { TwoFASettings } from './TwoFASettings/TwoFASettings';
import { set2FAOff, set2FAOn } from './reducer';
import { SettingsModalProps, THandleSubmit, TwoFAFormValues } from './types';
import { use2FA } from './use2FA';

const Profile: FC<React.PropsWithChildren<SettingsModalProps>> = () => {
  const { token2FA } = use2FA();
  const {
    id: userId,
    userName,
    primaryPhone,
  } = useAppSelector(UserInfoSelector);
  const dispatch = useAppDispatch();
  const { logout } = useAuthContext();
  const authDBService = useAuthDBService();

  const handle2FAOn: THandleSubmit<TwoFAFormValues> = useCallback(
    async (values) => {
      try {
        await dispatch(set2FAOn({ ...values, authDBService, userId })).unwrap();

        Notification.success({
          title: 'Successfully set on',
        });
        Notification.warning({
          title: `You have been logged out`,
        });
        return logout();
      } catch (error: any) {
        const response = JSON.parse(error?.message);
        return Notification.error({
          title: response?.statusText,
        });
      }
    },
    [authDBService, dispatch, Notification, userId],
  );

  const handle2FAOff = useCallback(
    async (values: TwoFAFormValues) => {
      try {
        await dispatch(
          set2FAOff({ ...values, authDBService, userId }),
        ).unwrap();

        Notification.success({
          title: 'Successfully set off',
        });
        Notification.warning({
          title: `You have been logged out`,
        });
        return logout();
      } catch (error: any) {
        const response = JSON.parse(error?.message);
        return Notification.error({
          title: response?.statusText,
        });
      }
    },
    [authDBService, dispatch, Notification, userId],
  );

  const handleSubmit = token2FA ? handle2FAOff : handle2FAOn;
  return (
    <SettingsForm
      defaultValues={{
        username: userName,
      }}
      onSubmit={handleSubmit}
    >
      <TwoFASettings
        phone={primaryPhone}
        userId={userId}
        userName={userName}
        token={token2FA}
      />
    </SettingsForm>
  );
};

export default Profile;
