import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { StyledProps } from '../../../shared/helpers/types';
import { ResendButtonProps } from '../types';

const Root = styled('span')(({ theme }: StyledProps) => ({
  display: 'flex',
  justifyContent: 'center',
  lineHeight: '24px',
  '& > .MuiLink-root': {
    fontSize: '15px',
    fontFamily: theme?.font?.main,
  },
}));

const ResendTimer = styled('div')(({ theme }: StyledProps) => ({
  fontSize: '15px',
  color: theme?.color?.typo?.secondary,
}));

export const ResendButton: FC<React.PropsWithChildren<ResendButtonProps>> = ({
  isTimerRunning,
  time,
  onClick,
  type,
}) => (
  <Root>
    {isTimerRunning ? (
      <ResendTimer>Resend after {time} sec</ResendTimer>
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        component="button"
        data-test-id={`profile-module__button--${type}-resend`}
        variant="caption"
        underline="none"
        onClick={onClick}
      >
        Resend code
      </Link>
    )}
  </Root>
);
