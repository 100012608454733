import { ChangeEvent } from 'react';

import { AuthStatus } from './const';
import { IServiceData, TService } from './types';

export const findService = (
  services: Array<IServiceData>,
  serviceName?: TService,
  status: AuthStatus = AuthStatus['2FA'],
) =>
  services.find((service) =>
    serviceName
      ? service.name === serviceName
      : service.status && service.status === status,
  );

export const trimInputValue = (
  event:
    | ChangeEvent<HTMLInputElement>
    | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  return {
    ...event,
    target: {
      ...event.target,
      value: event.target.value.trim(),
    },
  };
};

export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');

    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return null;
}
