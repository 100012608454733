export const MIN_SEARCH_LENGTH = 2;
export const INPUT_SEARCH_DELAY = 300;
export const MAX_RESULT_SEARCH = 20;
export const MIN_RESULT_SEARCH = 0;
export const MENU_TABS = {
  approved: 'all',
  applicants: 'all',
  qualified: 'all',
  waiting_for_review: 'all',
  pending: 'all',
  funded: 'all',
  rejected: 'all',
  contact: 'contacts',
  events: 'Events',
  event: 'Events',
  mailings: 'Mailing Lists',
};
