import { omit } from 'lodash';

import { Currency } from '../../services/Types/currencies.types';

import {
  LOCAL_STORAGE_KEY,
  privateCurrencyFields,
} from './CurrencySelector.consts';
import { Icon, IconContainer } from './CurrencySelector.styled';
import { PreparedCurrency } from './CurrencySelector.types';

export const prepareCurrenciesForSelect = (result: PreparedCurrency[]) =>
  result.map(({ id, icon }) => ({
    value: id,
    label: id,
    icon: (
      <IconContainer>
        {icon ? <Icon alt="currency" src={icon} /> : ''}
      </IconContainer>
    ),
  }));

export const createCurrencyChangeEvent = () => {
  const event = new CustomEvent('onChangeCurrency', {
    detail: {
      getCurrentCurrency: () => {
        try {
          const currency = localStorage.getItem(LOCAL_STORAGE_KEY);
          const parsed: Currency = currency ? JSON.parse(currency) : {};

          return parsed;
        } catch {
          return {};
        }
      },
    },
  });

  return event;
};

export const prepareCurrenciesList = (currencies: Currency[]) => {
  let filtered: PreparedCurrency[] = [];
  if (Array.isArray(currencies)) {
    filtered = currencies.reduce<PreparedCurrency[]>((acc, currency) => {
      if (!currency.isNav) {
        return acc;
      }

      acc.push({
        // eslint-disable-next-line no-underscore-dangle
        id: currency._id,
        ...omit(currency, privateCurrencyFields),
      });
      return acc;
    }, []);
  }

  const sorted = filtered.sort((a, b) => {
    const diff = b.weight - a.weight;
    if (diff !== 0) {
      return diff;
    }

    return a.id.localeCompare(b.id);
  });

  return sorted;
};
