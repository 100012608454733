import { createTheme, ThemeProvider } from '@mui/material/styles';
import { mergeWith } from 'lodash';
import { FC, useContext, useMemo } from 'react';
import {
  defaultTheme as UiKitDefaultTheme,
  darkTheme as UiKitDarkTheme,
  ThemeProvider as ReactUiKitThemeProvider,
} from 'react-ui-kit-exante';

import { ConfigContext } from '../config/ConfigContext';
import { useLocalStorage } from '../hooks/useLocalStorage';

import { LIGHT_THEME, LOCAL_STORAGE_KEY_COLOR_SCHEME } from './const';
import { TColorScheme } from './types';

import { theme as localTheme } from './index';

export const ComposedThemeProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { theme: brandingTheme } = useContext(ConfigContext);

  const { getItem } = useLocalStorage();

  const mode =
    (getItem(LOCAL_STORAGE_KEY_COLOR_SCHEME) as TColorScheme) || LIGHT_THEME;

  const UiKitTheme = mode === LIGHT_THEME ? UiKitDefaultTheme : UiKitDarkTheme;

  const themeValue = useMemo(() => {
    return createTheme(
      mergeWith(
        {},
        UiKitTheme,
        localTheme,
        brandingTheme,
        (objValue, srcValue) => {
          if (typeof objValue === 'string' && srcValue === '') {
            return objValue;
          }

          return undefined;
        },
      ),
    );
  }, [brandingTheme]);

  return (
    <ThemeProvider theme={themeValue}>
      <ReactUiKitThemeProvider theme={themeValue}>
        {children}
      </ReactUiKitThemeProvider>
    </ThemeProvider>
  );
};
