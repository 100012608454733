import { styled } from '@mui/material/styles';

import { StyledProps } from '../../../../../shared/helpers/types';

export const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '8px ',
  paddingRight: '8px',
}));

export const Header = styled('div')(({ theme }: StyledProps) => ({
  textTransform: 'uppercase',
  fontSize: '12px',
  color: theme?.color?.typo?.secondary,
  margin: '8px 0',
}));
