import { createBrowserHistory } from 'history';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { GlobalStyles, NotificationProvider } from 'react-ui-kit-exante';
import { PersistGate } from 'redux-persist/integration/react';

import { AuthProvider } from './components/auth/AuthProvider';
import { ErrorFallback } from './components/errorFallback';
import { RootLayout } from './components/layouts/RootLayout';
import { RouteList } from './components/router/const';
import { ServiceProvider } from './components/services/ServiceProvider';
import { ConfigProvider } from './config/ConfigContext';
import { getBaseName } from './shared/helpers/routingHelpers';
import { persistor, store } from './store';
import { ComposedThemeProvider } from './theme/ComposedThemeProvider';

const history = createBrowserHistory({ basename: getBaseName() });
window.runUIhistoryContainer = history;

const App = () => {
  return (
    <div className="RunUI">
      <HelmetProvider>
        <NotificationProvider />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider>
              <Router history={history}>
                <AuthProvider>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <ServiceProvider>
                      <ComposedThemeProvider>
                        <GlobalStyles />
                        <RootLayout routeList={RouteList} />
                      </ComposedThemeProvider>
                    </ServiceProvider>
                  </ErrorBoundary>
                </AuthProvider>
              </Router>
            </ConfigProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </div>
  );
};

export default App;
