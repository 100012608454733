import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getStringHash } from '../shared/helpers';

import { setFavouritesState } from './reducer';
import { FavouritesSelector } from './selector';
import { IFavourite } from './types';

export const useFavourites = (lastBreadcrumb?: any) => {
  const dispatch = useAppDispatch();
  const favourites = useAppSelector<IFavourite[]>(FavouritesSelector);
  const [isActiveFavourite, setIsActiveFavourite] = useState(false);

  const addToFavourites = ({ id, linkTo, text }: IFavourite) => {
    const newFavourites = [
      ...favourites,
      {
        id,
        linkTo,
        text,
      },
    ];

    dispatch(setFavouritesState(newFavourites));
  };

  const removeFromFavourites = (item: IFavourite) => {
    const newFavourites = favourites.filter(
      (favourite) => favourite.id !== item.id,
    );
    dispatch(setFavouritesState(newFavourites));
  };

  const toggleFavourite = (item: IFavourite) => {
    const isExist = favourites.find((favourite) => favourite.id === item.id);

    if (isExist) {
      removeFromFavourites(item);
      return;
    }

    addToFavourites(item);
  };

  useEffect(() => {
    if (lastBreadcrumb) {
      const { text, url } = lastBreadcrumb;
      const hashId = getStringHash(text + url);
      const hasFavourite = favourites.find(
        (favourite) => favourite.id === hashId,
      );
      setIsActiveFavourite(Boolean(hasFavourite));
    }
  }, [favourites, lastBreadcrumb]);

  return {
    toggleFavourite,
    favourites,
    removeFromFavourites,
    isActiveFavourite,
  };
};
