import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { COLLAPSED_MENU_WIDTH, MENU_WIDTH } from '../menu/const';

import { TStyledLayoutMainProps } from './types';

export const StyledLayoutWrapper = styled('div')`
  display: flex;

  width: 100%;
  min-height: 100vh;

  background-color: ${({ theme }) => theme?.color?.bg?.basic};
  color: ${({ theme }) => theme?.color.typo.primary};
`;

export const StyledLayoutMain = styled('main', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})(({ isCollapsed }: TStyledLayoutMainProps) => ({
  display: 'flex',
  flexDirection: 'column',

  flex: `0 0 calc(100% - ${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px)`,

  transition: 'flex-basis .4s ease-out',

  overflowX: 'auto',
}));
