import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchUserInfo } from '../../entities/user/reducer';
import { IUserData } from '../../entities/user/types';
import { SERVICES } from '../auth/const';

import { SettingsState, TwoFAFormValues } from './types';

const initialState = {
  services: SERVICES.map((service) => ({
    name: service,
    token: null,
  })),
} as SettingsState;

export const set2FAOn = createAsyncThunk(
  'settings/set2FAOn',
  async (props: TwoFAFormValues) => {
    const { code, url, userId, mainTokenId, tokenId, authDBService } = props;

    await authDBService.validateTokenCode(url, code);

    return authDBService.updateAuthFlows(userId, mainTokenId, tokenId);
  },
);

export const set2FAOff = createAsyncThunk(
  'settings/set2FAOff',
  async (props: TwoFAFormValues) => {
    const { code, url, userId, mainTokenId, authDBService } = props;

    await authDBService.validateTokenCode(url, code);

    return authDBService.updateAuthFlows(userId, mainTokenId);
  },
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      const { auths, tokens } = action.payload as IUserData;

      if (!auths && !tokens) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      state.services = state.services.map((service) => {
        const auth = auths.find((item) => item.service === service.name);

        if (auth) {
          const [authToken] = auth.tokens;

          const token = tokens.find((item) => item.id === authToken.tokenid);

          return {
            ...service,
            token: token || null,
          };
        }

        return service;
      });
    });
    builder.addCase(set2FAOn.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { service, tokenId, type },
        },
      } = action;

      // eslint-disable-next-line no-param-reassign
      state.services = state.services.map((item) => {
        if (item.name === service) {
          return {
            ...item,
            token: {
              type,
              name: `${type} ${service}`,
              id: tokenId,
            },
          };
        }
        return item;
      });
    });
    builder.addCase(set2FAOff.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { service },
        },
      } = action;

      // eslint-disable-next-line no-param-reassign
      state.services = state.services.map((item) => {
        if (item.name === service) {
          return {
            ...item,
            token: null,
          };
        }
        return item;
      });
    });
  },
});

export const settingsReducer = settingsSlice.reducer;
