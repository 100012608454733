import * as yup from 'yup';

const optionalField = yup.lazy((value) => {
  if (value !== undefined) {
    return yup.string().required();
  }
  return yup.mixed().notRequired();
});

const optionalPasswordField = yup.lazy((value) => {
  if (value !== undefined) {
    return yup
      .string()
      .required('Password is required')
      .oneOf([yup.ref('oldPassword'), null], "Passwords don't match")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&_-]{8,}$/,
        'Password should be at least 8 characters which contains at least one lower letter, one upper letter and one number.',
      );
  }
  return yup.mixed().notRequired();
});

export const detailsSchema = yup.object().shape({
  nameSurname: optionalField,
  phone: optionalField,
  oldPassword: optionalField,
  newPassword: optionalPasswordField,
});
