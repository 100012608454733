import { createSlice } from '@reduxjs/toolkit';

import { MenuConfig, TCrmBookmarksData } from '../../config/types';

import { IFavourite } from './types';

export type MenuState = {
  crm: {
    bookmarks: TCrmBookmarksData;
    menu: MenuConfig;
  };
  cprm: {
    bookmarks: TCrmBookmarksData;
    menu: MenuConfig;
  };
  favourites: IFavourite[];
  config: MenuConfig;
};
const initialState: MenuState = {
  crm: {
    bookmarks: null,
    menu: [],
  },
  cprm: {
    bookmarks: null,
    menu: [],
  },
  favourites: [],
  config: [],
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setCrmBookmarksState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.crm.bookmarks = action.payload;
    },
    setCprmBookmarksState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.cprm.bookmarks = action.payload;
    },
    setCrmMenuState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.crm.menu = action.payload;
    },
    setCprmMenuState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.cprm.menu = action.payload;
    },
    setFavouritesState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.favourites = action.payload;
    },
    setMenuConfig: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.config = action.payload;
    },
    setMenuInitialState: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const menuReducer = menuSlice.reducer;

export const {
  setCrmBookmarksState,
  setCprmBookmarksState,
  setCrmMenuState,
  setCprmMenuState,
  setFavouritesState,
  setMenuConfig,
  setMenuInitialState,
} = menuSlice.actions;
