import { useContext } from 'react';

import { LOCAL_STORAGE_KEY_LAST_PAGE } from '../components/auth/const';
import { ConfigContext } from '../config/ConfigContext';

import { useLocalStorage } from './useLocalStorage';

export const useHomePath = () => {
  const { vars } = useContext(ConfigContext);
  const { getItem } = useLocalStorage();
  const lastPath = getItem(LOCAL_STORAGE_KEY_LAST_PAGE);

  return lastPath || vars?.HOME_PATH;
};
