import { useMemo } from 'react';

import { SettingsServicesSelector } from '../../../entities/user/ProfileSettings/selectors';
import { useAppSelector } from '../../../store/hooks';
import { IService } from '../../profile/types';
import { OAuthServices } from '../selectors';

export const useServices = () => {
  const services = useAppSelector(SettingsServicesSelector);
  const allowedOauthServices = useAppSelector(OAuthServices);

  const allowedServices = useMemo(() => {
    let filteredServices = [];

    if (allowedOauthServices.length !== 0) {
      filteredServices = services.filter((service: IService) =>
        allowedOauthServices.includes(service.name),
      );
    }

    return filteredServices;
  }, [services, allowedOauthServices]);

  const allowedServiceNames = useMemo(
    () => allowedServices.map((service: IService) => service.name),
    [allowedServices],
  );

  return {
    allowedServices,
    allowedServiceNames,
  };
};
