interface UseCustomEventProps {
  eventType: string;
}

export const useCustomEvent = ({ eventType }: UseCustomEventProps) => {
  const sendEvent = (data: any) => {
    const event = new CustomEvent(eventType, { detail: data });
    window.dispatchEvent(event);
  };

  const handleEvent = (callback: (data: any) => void) => {
    const listener = (event: Event) => {
      const detail = (event as CustomEvent).detail;
      callback(detail);
    };
    window.addEventListener(eventType, listener);

    return () => {
      window.removeEventListener(eventType, listener);
    };
  };

  return {
    sendEvent,
    handleEvent,
  };
};
