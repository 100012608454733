import { FC, ReactNode, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Header } from '../header/Header';
import { Menu } from '../menu/Menu';
import { PrivateRoute } from '../router/PrivateRoute';
import { IRoute } from '../router/types';
import { useServiceContext } from '../services/ServiceProvider';
import { PageWrapper } from '../shared/PageWrapper/PageWrapper';

import { StyledLayoutWrapper, StyledLayoutMain } from './RootLayout.styled';
import { RouteLayoutProps } from './types';

const RenderRoutes: (routeList: Array<IRoute>) => ReactNode = (routeList) => {
  const { ctx } = useServiceContext();
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const handleCollapseMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  const renderRoute: FC<React.PropsWithChildren<IRoute>> = ({
    path,
    component: Component,
    layout: Layout,
    routes,
    isNotPrivate = false,
    module,
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356#issuecomment-435708501
  }): any => {
    const RouteComponent = isNotPrivate ? Route : PrivateRoute;
    let render = <Component />;

    if (Layout) {
      render = (
        <Layout {...ctx}>
          <Component />
        </Layout>
      );
    }

    return [
      <RouteComponent key={path} exact path={path} module={module}>
        {!isNotPrivate ? (
          <>
            <Menu
              isCollapsed={isMenuCollapsed}
              handleCollapseMenu={handleCollapseMenu}
            />
            <StyledLayoutMain
              className="LayoutMain"
              isCollapsed={isMenuCollapsed}
            >
              <Header />
              <PageWrapper>{render}</PageWrapper>
            </StyledLayoutMain>
          </>
        ) : (
          render
        )}
      </RouteComponent>,
      routes?.map((route) =>
        renderRoute({
          ...route,
          layout: route.layout || Layout,
        }),
      ),
    ];
  };

  return routeList.map(renderRoute);
};

export const RootLayout: FC<React.PropsWithChildren<RouteLayoutProps>> = ({
  routeList,
}) => (
  <StyledLayoutWrapper className="LayoutWrapper">
    <Switch>
      {RenderRoutes(routeList)}
      <Route exact path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  </StyledLayoutWrapper>
);
