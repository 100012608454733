import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ComponentType } from 'react';

import CloseIcon from '../../../assets/i/Close.svg';
import InfoIcon from '../../../assets/i/Info.svg';
import { StyledProps } from '../../../shared/helpers/types';
import {
  ICardHeaderProps,
  ICardImageProps,
  ICardInfoAccentProps,
  ILightTooltipProps,
} from '../types';

export const CardRoot = styled(Card)(({ theme }: StyledProps) => ({
  flexBasis: '400px',
  height: '552px',
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  padding: '24px',
  margin: '0 8px',
  boxSizing: 'border-box',
  backgroundColor: theme?.color.bg.primary,
}));

const Title = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const CloseButton = styled('span')(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}));

export const CardHeader = styled(
  ({ onClick, title, type, ...rest }: ICardHeaderProps) => (
    <div {...rest}>
      <Title>{title}</Title>
      <CloseButton
        onClick={onClick}
        data-test-id={`profile-module__button--${type}-close`}
      >
        <img alt="close" src={CloseIcon} />
      </CloseButton>
    </div>
  ),
)(({ theme }: StyledProps) => ({
  fontSize: '15px',
  fontFamily: theme?.font?.main,
  fontWeight: 600,
  color: theme?.color.typo.primary,
  lineHeight: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '16px',
})) as ComponentType<React.PropsWithChildren<ICardHeaderProps>>;

export const CardContent = styled('div')(({ theme }: StyledProps) => ({
  fontFamily: theme?.font?.main,
  color: theme?.color.typo.primary,
  '& > *': {
    marginBottom: '16px',
  },
}));

export const CardActions = styled('div')(() => ({
  width: '100%',
  '& > *': {
    marginBottom: '24px',
    '&:last-child': {
      margin: 0,
    },
  },
}));

export const CardInfo = styled('div')(() => ({
  marginBottom: '16px',
  lineHeight: '24px',
  fontSize: '15px',
}));

const LightTooltip = styled(
  ({ children, className, ...props }: ILightTooltipProps) => (
    <Tooltip
      TransitionProps={{ enter: false, exit: false }}
      {...props}
      classes={{ popper: className }}
    >
      {children}
    </Tooltip>
  ),
)(({ theme }: StyledProps) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme?.color.bg.primary,
    color: theme?.color.typo.secondary,
    textAlign: 'center',
    boxShadow: theme?.shadows[1],
    fontSize: '13px',
    maxWidth: '219px',
    padding: '16px',
    lineHeight: '16px',
    fontFamily: theme?.font?.main,
  },
  '&:before': {
    content: '""',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '8px solid #fff',
    position: 'absolute',
    bottom: '7px',
    left: '50%',
    transform: 'translateX(-50%)',
    filter: `drop-shadow(0px 1px 1px rgba(0,0,0,.6))`,
    zIndex: -1,
  },
  '&:after': {
    content: '""',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '8px solid #fff',
    position: 'absolute',
    bottom: '7px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
})) as ComponentType<React.PropsWithChildren<ILightTooltipProps>>;

export const CardInfoAccent = styled(
  ({ children, text, ...rest }: ICardInfoAccentProps) => (
    <div {...rest}>
      {children}
      <LightTooltip title={text} placement="top">
        <img alt="info" src={InfoIcon} />
      </LightTooltip>
    </div>
  ),
)(() => ({
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginLeft: '8px',
  },
})) as ComponentType<React.PropsWithChildren<ICardInfoAccentProps>>;

export const CardImage = styled(({ alt, src, ...rest }: ICardImageProps) => (
  <div {...rest}>
    <img alt={alt} src={src} />
  </div>
))(() => ({
  display: 'flex',
  justifyContent: 'center',
  minWidth: '208px',
  maxHeight: '208px',
})) as ComponentType<React.PropsWithChildren<ICardImageProps>>;
