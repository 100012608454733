import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TDefaultTheme } from 'react-ui-kit-exante';

import { ReactComponent as EmailIcon } from '../../../assets/i/Email.svg';
import { ReactComponent as EmailActivatedIcon } from '../../../assets/i/EmailActivated.svg';
import { ReactComponent as EmailDisabledIcon } from '../../../assets/i/EmailDisabled.svg';
import { ReactComponent as EmailHoveredIcon } from '../../../assets/i/EmailHovered.svg';
import { ReactComponent as GAIcon } from '../../../assets/i/GA.svg';
import { ReactComponent as GAActivatedIcon } from '../../../assets/i/GAActivated.svg';
import { ReactComponent as GADisabledIcon } from '../../../assets/i/GADisabled.svg';
import { ReactComponent as GAHoveredIcon } from '../../../assets/i/GAHovered.svg';
import { ReactComponent as SMSIcon } from '../../../assets/i/SMS.svg';
import { ReactComponent as SMSActivatedIcon } from '../../../assets/i/SMSActivated.svg';
import { ReactComponent as SMSDisabledIcon } from '../../../assets/i/SMSDisabled.svg';
import { ReactComponent as SMSHoveredIcon } from '../../../assets/i/SMSHovered.svg';
import { StyledProps } from '../../../shared/helpers/types';
import { FormState } from '../const';
import { TwoFASettingsProps } from '../types';

import { TwoFASettingsCard } from './TwoFASettingsCard';
import { TwoFASettingsEmail } from './TwoFASettingsEmail';
import { TwoFASettingsGA } from './TwoFASettingsGA';
import { TwoFASettingsSMS } from './TwoFASettingsSMS';
import { useStyles } from './styles';

const Root = styled('div')(({ theme }: StyledProps) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme?.color.bg.primary,
  minHeight: 'calc(100vh - 56px)',
}));

const Title = styled(Typography)(({ theme }: StyledProps) => ({
  fontSize: '32px',
  fontFamily: 'FoxSansPro, sans-serif',
  textAlign: 'center',
  margin: '24px 0',
  color: theme?.color.typo.primary,
}));

export const TwoFASettings: FC<React.PropsWithChildren<TwoFASettingsProps>> = ({
  phone,
  token,
  userId,
  userName,
}) => {
  const classes = useStyles();
  const [active, setActive] = useState<FormState | null>(null);
  const { setValue } = useFormContext();
  const theme: TDefaultTheme = useTheme();

  const handleClose = () => {
    setActive(null);
  };

  const handleCardClick = (value: FormState) => {
    if (value === active) {
      handleClose();
      return;
    }
    setValue(FormState.CODE, '');
    setActive(value);
  };

  return (
    <Root>
      <Title variant="h1">Choose 2-step authentication type</Title>

      <div className={classes.radioGroup}>
        <TwoFASettingsCard
          active={active}
          Component={TwoFASettingsGA}
          handleClose={handleClose}
          token={token}
          userId={userId}
          userName={userName}
          icon={{
            activated: <GAActivatedIcon />,
            disabled: <GADisabledIcon />,
            enabled: <GAIcon stroke={theme?.color.typo.primary} />,
            hovered: <GAHoveredIcon />,
          }}
          dataTestId="profile-module__2fa-card--ga"
          onClick={handleCardClick}
          value={FormState.GA}
          title="Via one-time password application"
          text="Use 2-Step Verification codes generated on your phone each time you log in to user area"
        />
        <TwoFASettingsCard
          active={active}
          Component={TwoFASettingsEmail}
          handleClose={handleClose}
          token={token}
          userName={userName}
          userId={userId}
          icon={{
            activated: <EmailActivatedIcon />,
            disabled: <EmailDisabledIcon />,
            enabled: <EmailIcon stroke={theme?.color.typo.primary} />,
            hovered: <EmailHoveredIcon />,
          }}
          dataTestId="profile-module__2fa-card--email"
          onClick={handleCardClick}
          value={FormState.EMAIL}
          title="Via Email"
          text="Use messages that we send to your email address each time you log in to user area"
        />
        <TwoFASettingsCard
          active={active}
          Component={TwoFASettingsSMS}
          handleClose={handleClose}
          token={token}
          phone={phone}
          userName={userName}
          userId={userId}
          icon={{
            activated: <SMSActivatedIcon stroke={theme?.color.typo.primary} />,
            disabled: <SMSDisabledIcon />,
            enabled: <SMSIcon stroke={theme?.color.typo.primary} />,
            hovered: <SMSHoveredIcon />,
          }}
          dataTestId="profile-module__2fa-card--sms"
          onClick={handleCardClick}
          value={FormState.SMS}
          title="Via SMS"
          text="Use messages that we send to your phone each time you log in to user area"
        />
      </div>
    </Root>
  );
};
