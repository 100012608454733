import { RUN_URLS } from '../const';

const branchNameSearch = (p: string) => /ERU-\d*|release/.test(p);

export const getBaseName = () => {
  const path = window.location.pathname.split('/');
  const branchName = path.find(branchNameSearch);
  return branchName ? `/${branchName}/` : '/';
};

export const addBaseTag = () => {
  const baseTag = document.createElement('base');
  const path = window.location.pathname.split('/');
  const branchIdx = path.findIndex(branchNameSearch);
  if (branchIdx !== -1) {
    baseTag.href = `${
      window.location.origin + path.slice(0, branchIdx + 1).join('/')
    }/`;
    document.head.prepend(baseTag);
  }
};

export const isExante = () =>
  RUN_URLS.some((url) => window.location.href.includes(url));
