import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'react-ui-kit-exante';

import GAFilledIcon from '../../../assets/i/GAFilledIcon.svg';
import GASentIcon from '../../../assets/i/GASentIcon.svg';
import GATitleIcon from '../../../assets/i/GATitleIcon.svg';
import { useAuthDBService } from '../../services/AuthDB.service';
import { DigitInput } from '../DigitInput/DigitInput';
import { useDigitInput } from '../DigitInput/useDigitInput';
import { FormState, GA_DIGIT_INPUT_LENGTH } from '../const';
import { checkTotpToken } from '../helpers';
import { useQRCode } from '../hooks';
import { ModalContentProps } from '../types';

import {
  CardContent,
  CardRoot,
  CardHeader,
  CardActions,
  CardInfo,
  CardImage,
} from './Card';
import { QRCode } from './QRCode';

export const useStyles = makeStyles({
  subtitleCls: {
    fontWeight: 'bold',
  },
  body2Cls: {
    margin: '4px 0 16px 16px',
  },
  controlCls: {
    marginBottom: '24px',
  },
  inputCls: {
    padding: '8px 8px 8px 16px',
  },
});

const GAIcon = styled('img')(() => ({
  marginRight: '16px',
}));

const CardImageGA = styled(CardImage)(() => ({
  marginBottom: '64px',
}));

export const TwoFASettingsGA: FC<
  React.PropsWithChildren<ModalContentProps>
> = ({
  handleClose,
  userName,
  title = 'One-time password application',
  token,
  buttonText,
  userId,
}) => {
  const { setValue } = useFormContext();

  const { refCallback, key } = useQRCode(userName as string);

  const { handleInputChange, inputValue, isFilled } = useDigitInput(
    GA_DIGIT_INPUT_LENGTH,
  );

  const authDBService = useAuthDBService();

  useEffect(() => {
    if (!key || token) {
      return;
    }

    (async () => {
      try {
        const { mainTokenId, tokenId, url } = await checkTotpToken(
          userId,
          authDBService,
          key,
          FormState.GA,
        );

        setValue('tokenId', tokenId);
        setValue('mainTokenId', mainTokenId);
        setValue('url', url);
      } catch (error: any) {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }
    })();
  }, [key]);

  return (
    <CardRoot>
      <CardHeader
        title={
          <>
            <GAIcon alt="icon" src={GATitleIcon} />
            {title}
          </>
        }
        onClick={handleClose}
        type={FormState.GA}
      />
      <CardContent>
        {token ? (
          <CardImageGA alt="image" src={isFilled ? GAFilledIcon : GASentIcon} />
        ) : (
          <>
            <CardInfo>
              You will need, for example, Google Authenticator to complete this
              progress.
            </CardInfo>
            <QRCode refCallback={refCallback} userName={userName} />
          </>
        )}
        <CardInfo>
          Enter the 6-digit authentication code generated by your app:
        </CardInfo>
      </CardContent>
      <CardActions>
        <DigitInput
          dataTestId={`profile-module__input--${FormState.GA}`}
          onChange={handleInputChange}
          value={inputValue}
          length={GA_DIGIT_INPUT_LENGTH}
        />
        <Button
          data-test-id={`profile-module__button--${FormState.GA}-verify`}
          fullWidth
          autoFocus
          type="submit"
          disabled={!isFilled}
        >
          {buttonText}
        </Button>
      </CardActions>
    </CardRoot>
  );
};
