import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Config } from './types';

const initialState: Config = {
  apis: null,
  vars: {},
  modules: {},
  theme: {},
  menu: [],
  features: {},
  auth_flows: [],
  logo: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig(state, action: PayloadAction<Record<string, unknown>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateConfig } = configSlice.actions;
