import { useMemo } from 'react';

import { useAppSelector } from '../store/hooks';

import { getBookmarksDistributedByTabs } from './helpers';
import { CprmMenuStateSelector, CrmMenuStateSelector } from './selectors';

export const useCrmCprmBookmarks = () => {
  const crmMenuState = useAppSelector(CrmMenuStateSelector);
  const cprmMenuState = useAppSelector(CprmMenuStateSelector);

  return useMemo(
    () => ({
      crmBookmarks: getBookmarksDistributedByTabs(crmMenuState, 'crm'),
      cprmBookmarks: getBookmarksDistributedByTabs(cprmMenuState, 'cprm'),
    }),
    [cprmMenuState, crmMenuState],
  );
};
