import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { Button } from 'react-ui-kit-exante';

import LockEmptyIcon from '../../../assets/i/LockEmpty.svg';
import LockFilledIcon from '../../../assets/i/LockFilled.svg';
import LockSentIcon from '../../../assets/i/LockSent.svg';
import { DigitInput } from '../DigitInput/DigitInput';
import { useDigitInput } from '../DigitInput/useDigitInput';
import { FormState, SMS_DIGIT_INPUT_LENGTH } from '../const';
import { ModalContentProps } from '../types';

import {
  CardContent,
  CardRoot,
  CardHeader,
  CardActions,
  CardInfo,
  CardImage,
  CardInfoAccent,
} from './Card';
import { ResendButton } from './ResendButton';

export const useStyles = makeStyles({
  subtitleCls: {
    fontWeight: 'bold',
  },
  body2Cls: {
    margin: '4px 0 16px 16px',
  },
  controlCls: {
    marginLeft: '16px',
  },
  inputCls: {
    padding: '8px 8px 8px 16px',
  },
});
export const TwoFASettingsSMS: FC<
  React.PropsWithChildren<ModalContentProps>
> = ({
  handleClose,
  phone,
  title = '2-step authentication via phone',
  buttonText,
  sendCode,
  isTimerRunning,
  time,
}) => {
  const { handleInputChange, inputValue, isFilled } = useDigitInput(
    SMS_DIGIT_INPUT_LENGTH,
  );

  const IconSrc = isFilled ? LockFilledIcon : LockEmptyIcon;

  return (
    <CardRoot>
      <CardHeader title={title} onClick={handleClose} type={FormState.SMS} />
      <CardContent>
        <CardImage
          alt="image"
          src={isTimerRunning && !isFilled ? LockSentIcon : IconSrc}
        />
        <CardInfo>
          Enter the authentication code below we send to
          <CardInfoAccent
            text="We will use the phone currently saved in your profile.
            If you want to use another phone, please change it in your profile Settings"
          >
            {phone}
          </CardInfoAccent>
        </CardInfo>
      </CardContent>
      <CardActions>
        <ResendButton
          onClick={sendCode}
          type={FormState.SMS}
          isTimerRunning={isTimerRunning}
          time={time}
        />
        <DigitInput
          dataTestId={`profile-module__input--${FormState.SMS}`}
          length={SMS_DIGIT_INPUT_LENGTH}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button
          data-test-id={`profile-module__button--${FormState.SMS}-verify`}
          fullWidth
          autoFocus
          type="submit"
          disabled={!isFilled}
        >
          {buttonText}
        </Button>
      </CardActions>
    </CardRoot>
  );
};
