import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Button, TDefaultTheme } from 'react-ui-kit-exante';

import { useApi } from '../../../../../../../config/useApi';
import { useLogHandlePageLoadingTime } from '../../../../../../../hooks/useLogHandleTime';
import { authWithGoogle } from '../../../../../../services/Api/helpers';
import { useConnectToken } from '../../../../../hooks/useConnectToken';
import { ILoginControls } from '../../types';

export const LoginControls: FC<React.PropsWithChildren<ILoginControls>> = ({
  icon,
  hint,
}) => {
  const { getAuthDBBaseUrl } = useApi();
  const { startStartLoginTime } = useLogHandlePageLoadingTime();
  const theme: TDefaultTheme = useTheme();
  const { setConnectProvider } = useConnectToken();

  const handleClick = () => {
    setConnectProvider(hint);
    startStartLoginTime();
    const baseUrl = getAuthDBBaseUrl();
    authWithGoogle(`${baseUrl}/auth/authenticate`, {
      login_hint: hint,
      client_id: 'checkpoint',
      redirect_uri: 'https://checkpoint-stage.exante.eu/api/1.0/oauth/code',
      response_type: 'code',
      scope: '',
    });
  };

  return (
    <Button
      fullWidth
      color="transparent"
      icon={<img alt={hint} src={icon} />}
      sx={{
        mt: '16px',
        mb: '24px',
        height: '48px',
        borderColor: theme?.color?.input?.border,
        '&:hover': {
          borderColor: theme?.color?.input?.border,
        },
      }}
      onClick={handleClick}
      variant="outlined"
    />
  );
};
