import { getMatchLink } from '../menu/helpers';
import { MenuItem } from '../menu/types';

import { MENU_TABS } from './GlobalSearch.consts';
import {
  MenuSearchResult,
  CRMSearchResult,
  TabsResultsCount,
} from './GlobalSearch.types';

export const mergeInTurn = <D = Record<string, unknown>>(
  arr1: D[],
  arr2: D[],
) => {
  const acc: D[] = [];
  const largestArrLength = Math.max(arr1.length, arr2.length);

  for (let i = 0; i < largestArrLength; i += 1) {
    if (arr1[i]) {
      acc.push(arr1[i]);
    }
    if (arr2[i]) {
      acc.push(arr2[i]);
    }
  }

  return acc;
};

export const getSearchResults = (
  allowedMenuItems: MenuItem[],
  consolidatedData: { result: CRMSearchResult[]; tabs: TabsResultsCount },
  value: string,
) => {
  const menuSearchBadgeCount: TabsResultsCount = {};
  const searchResults = allowedMenuItems.reduce(
    (acc: MenuSearchResult[], menuItem: MenuItem) => {
      const tabsResultsCount: TabsResultsCount = {};
      const crmResults = consolidatedData?.result?.filter(
        (el: { tab: string }) => {
          const menuTab = MENU_TABS[el.tab as keyof typeof MENU_TABS] || el.tab;
          const isSearchResult = menuItem.children?.some(
            (child) =>
              child.crmId === `crm-tab-${menuTab}` ||
              child.crmId === `cprm-tab-${menuTab}`,
          );
          if (isSearchResult) {
            tabsResultsCount[menuTab] =
              consolidatedData.tabs[
                menuTab as keyof typeof consolidatedData.tabs
              ];
          }
          return isSearchResult;
        },
      );

      const shaperMenuItem = menuItem.children?.find(
        (el) => el.defaultLink === '/ns/events',
      );

      const shaperResults =
        menuItem.children && shaperMenuItem
          ? consolidatedData?.result?.filter((el) => {
              return (
                !el.id &&
                MENU_TABS[el.tab as keyof typeof MENU_TABS] &&
                shaperMenuItem?.children?.some((menu) => {
                  const isResult = menu?.linkTo?.includes(el.tab);
                  if (isResult) {
                    tabsResultsCount[el.tab] = consolidatedData.tabs[el.tab];
                  }
                  return isResult;
                })
              );
            })
          : [];

      const menuResults = menuItem.children?.reduce(
        (menuRes: MenuItem[], child: MenuItem) => {
          const link = getMatchLink(child.linkTo || child.defaultLink);
          menuSearchBadgeCount[link] = 0;

          if (child.text.toLowerCase().includes(value.toLowerCase())) {
            menuSearchBadgeCount[link] += 1;
            menuRes.push(child);
          }

          if (child.children) {
            const innerMenuSearchRes = child.children.filter((item) => {
              return item.text.toLowerCase().includes(value.toLowerCase());
            });

            if (innerMenuSearchRes.length) {
              innerMenuSearchRes.forEach((item) => {
                const innerLink = getMatchLink(item.linkTo || item.defaultLink);
                menuSearchBadgeCount[innerLink] = 1;
              });
              menuRes.push(...innerMenuSearchRes);
            }
          }
          return menuRes;
        },
        [],
      );
      if (
        crmResults.length ||
        shaperResults.length ||
        menuResults?.length ||
        menuItem.text.toLowerCase().includes(value.toLowerCase())
      ) {
        let resultsCount = 0;
        Object.values(tabsResultsCount).forEach((el) => {
          resultsCount += el;
        });
        resultsCount += Number(menuResults?.length);

        acc.push({
          title: menuItem.text,
          icon: menuItem.iconSrc?.active,
          link: menuItem.defaultLink || menuItem.linkTo,
          resultsCount,
          crmResults,
          shaperResults,
          menuResults,
        });
      }
      return acc;
    },
    [],
  );
  return {
    searchResults,
    menuSearchBadgeCount,
  };
};
