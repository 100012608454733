import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ConfigContext } from '../../../config/ConfigContext';
import { TCrmMenuItem } from '../../../config/types';
import { useBOBookmarks } from '../../../config/useBOBookmarks';
import { useCrmCprmBookmarks } from '../../../config/useCrmCprmBookmarks';
import { useMenuConfig } from '../../../config/useMenuConfig';
import { useCustomEvent } from '../../../hooks/useCustomEvent';

import {
  getBookmarksBreadcrumbs,
  getBreadcrumbFromUrl,
  getBreadcrumbs,
  getDefaultHomeCrumb,
  getEnrichedBreadcrumbs,
  getSelectedBookmarkTab,
  getUrl,
  getUrlBasedLastCrumb,
} from './helpers';

export const useBreadcrumbs = () => {
  const { getMenuConfig } = useMenuConfig();
  const menuConfig = getMenuConfig();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const url = getUrl(hash, pathname, search);

  const { vars } = useContext(ConfigContext);
  const { crmBookmarks, cprmBookmarks } = useCrmCprmBookmarks();
  const { boBookmarks, requestBoBookmarks } = useBOBookmarks();

  let breadcrumbs = useMemo(
    () => getBreadcrumbs(menuConfig, url),
    [menuConfig, url],
  );
  let bookmarksBreadcrumbs;
  let lastSelectedBookmark: TCrmMenuItem | undefined;

  const { handleEvent: handleCreate } = useCustomEvent({
    eventType: 'onCreateBookmark',
  });
  const { handleEvent: handleDelete } = useCustomEvent({
    eventType: 'onDeleteBookmark',
  });
  const { handleEvent: handleUpdate } = useCustomEvent({
    eventType: 'onUpdateBookmark',
  });

  const history = useHistory();

  const lastCrumb = getUrlBasedLastCrumb(url);

  // TODO: the values from the variables should be placed in useState
  const bookmarks = [
    ...(crmBookmarks || []),
    ...(cprmBookmarks || []),
    ...(boBookmarks || []),
  ];

  useEffect(() => {
    requestBoBookmarks();
  }, []);

  if (bookmarks.length) {
    const breadcrumbUrl = getBreadcrumbFromUrl(url);

    const selectedBookmarkTab = getSelectedBookmarkTab(
      bookmarks,
      breadcrumbUrl,
    );
    bookmarksBreadcrumbs = getBookmarksBreadcrumbs(selectedBookmarkTab);

    if (bookmarksBreadcrumbs) {
      lastSelectedBookmark = bookmarksBreadcrumbs.find((el: TCrmMenuItem) =>
        el.linkTo.includes(breadcrumbUrl),
      );
    }
    if (isEmpty(breadcrumbs)) {
      breadcrumbs = getEnrichedBreadcrumbs(
        url,
        menuConfig,
        lastCrumb,
        lastSelectedBookmark,
        selectedBookmarkTab,
      );
    }
  }

  const handleChangeBookmark = (bookmarkUrl: string) => {
    const goToUrl = () => {
      history.push(bookmarkUrl);
    };

    const bookmark = new CustomEvent('onChangeBookmark', {
      detail: {
        onBookmarkChange: goToUrl,
      },
    });

    window.dispatchEvent(bookmark);
  };

  const handleCreateEventCallback = useCallback(
    async ({ serviceName }: { serviceName: string }) => {
      if (serviceName === 'bo') {
        await requestBoBookmarks(true);
      }
    },
    [],
  );

  const handleDeleteUpdateEventCallback = useCallback(
    async ({ serviceName }: { serviceName: string }) => {
      if (serviceName === 'bo') {
        await requestBoBookmarks(true);
        if (lastSelectedBookmark) {
          handleChangeBookmark(lastSelectedBookmark.linkTo);
        }
      }
    },
    [lastSelectedBookmark],
  );

  const selectedCrumb =
    (lastSelectedBookmark as TCrmMenuItem) || breadcrumbs.at(-1);

  useEffect(() => {
    const unsubCreate = handleCreate(handleCreateEventCallback);
    const unsubDelete = handleDelete(handleDeleteUpdateEventCallback);
    const unsubUpdate = handleUpdate(handleDeleteUpdateEventCallback);

    return () => {
      unsubCreate();
      unsubDelete();
      unsubUpdate();
    };
  }, [handleCreateEventCallback, handleDeleteUpdateEventCallback]);

  const enrichedBreadcrumbs = useMemo(
    () => [
      getDefaultHomeCrumb(url, vars?.HOME_PATH),
      ...breadcrumbs.filter((link) => !link.disabled),
    ],
    [getDefaultHomeCrumb, url, vars?.HOME_PATH, breadcrumbs],
  );

  const selectedBookmark = lastSelectedBookmark
    ? lastSelectedBookmark.linkTo
    : undefined;

  return {
    breadcrumbs: enrichedBreadcrumbs,
    bookmarks: bookmarksBreadcrumbs,
    selectedBookmark,
    lastBreadcrumb: { ...selectedCrumb, url },
    handleChangeBookmark,
  };
};
